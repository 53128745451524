import Context from './context';
import ExchangeService from './services/exchange';
import FuturesService from './services/futures';
import KwentaTokenService from './services/kwentaToken';
import PerpsV3 from './services/perpsV3';
import PricesService from './services/prices';
import ReferralsService from './services/referrals';
import StakingMigrationService from './services/stakingMigration';
import StatsService from './services/stats';
import SynthsService from './services/synths';
import SystemService from './services/system';
import TransactionsService from './services/transactions';
export default class KwentaSDK {
    constructor(context) {
        this.context = new Context(context);
        this.exchange = new ExchangeService(this);
        this.futures = new FuturesService(this);
        this.prices = new PricesService(this);
        this.synths = new SynthsService(this);
        this.transactions = new TransactionsService(this);
        this.kwentaToken = new KwentaTokenService(this);
        this.stats = new StatsService(this);
        this.system = new SystemService(this);
        this.perpsV3 = new PerpsV3(this);
        this.stakingMigration = new StakingMigrationService(this);
        this.referrals = new ReferralsService(this);
    }
    setProvider(provider) {
        return this.context.setProvider(provider);
    }
    setSigner(signer) {
        return this.context.setSigner(signer);
    }
}
