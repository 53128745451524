import { Contract as EthCallContract } from 'ethcall';
import BoostNftABI from './abis/BoostNFT.json';
import DappMaintenanceABI from './abis/DappMaintenance.json';
import ERC20ABI from './abis/ERC20.json';
import EscrowMigratorABI from './abis/EscrowMigrator.json';
import ExchangeRatesABI from './abis/ExchangeRates.json';
import FuturesMarketDataABI from './abis/FuturesMarketData.json';
import FuturesMarketSettingsABI from './abis/FuturesMarketSettings.json';
import KwentaArrakisVaultABI from './abis/KwentaArrakisVault.json';
import KwentaStakingRewardsABI from './abis/KwentaStakingRewards.json';
import KwentaStakingRewardsV2ABI from './abis/KwentaStakingRewardsV2.json';
import MultipleMerkleDistributorABI from './abis/MultipleMerkleDistributor.json';
import MultipleMerkleDistributorOpABI from './abis/MultipleMerkleDistributorOp.json';
import MultipleMerkleDistributorPerpsV2ABI from './abis/MultipleMerkleDistributorPerpsV2.json';
import PerpsV2MarketABI from './abis/PerpsV2Market.json';
import PerpsV2MarketDataABI from './abis/PerpsV2MarketData.json';
import PerpsV2MarketSettingsABI from './abis/PerpsV2MarketSettings.json';
import PerpsV3AccountProxyABI from './abis/PerpsV3AccountProxy.json';
import PerpsV3MarketProxyABI from './abis/PerpsV3MarketProxy.json';
import RewardEscrowABI from './abis/RewardEscrow.json';
import RewardEscrowV2ABI from './abis/RewardEscrowV2.json';
import StakingRewardsABI from './abis/StakingRewards.json';
import SupplyScheduleABI from './abis/SupplySchedule.json';
import SynthRedeemerABI from './abis/SynthRedeemer.json';
import SynthUtilABI from './abis/SynthUtil.json';
import SystemStatusABI from './abis/SystemStatus.json';
import { ADDRESSES } from './constants';
import { BatchClaimer__factory, BoostNFT__factory, ERC20__factory, EscrowMigrator__factory, ExchangeRates__factory, Exchanger__factory, FuturesMarketData__factory, FuturesMarketSettings__factory, KwentaArrakisVault__factory, KwentaStakingRewardsV2__factory, KwentaStakingRewards__factory, MultipleMerkleDistributorOp__factory, MultipleMerkleDistributorPerpsV2__factory, MultipleMerkleDistributor__factory, PerpsV3AccountProxy__factory, PerpsV3MarketProxy__factory, Pyth__factory, RewardEscrowV2__factory, RewardEscrow__factory, SmartMarginAccountFactory__factory, StakingRewards__factory, SupplySchedule__factory, SynthRedeemer__factory, SynthSwap__factory, SynthUtil__factory, Synthetix__factory, SystemSettings__factory, SystemStatus__factory, VKwentaRedeemer__factory, VeKwentaRedeemer__factory, } from './types';
import { PerpsV2MarketData__factory } from './types/factories/PerpsV2MarketData__factory';
import { PerpsV2MarketSettings__factory } from './types/factories/PerpsV2MarketSettings__factory';
export const getPerpsV2MarketMulticall = (marketAddress) => new EthCallContract(marketAddress, PerpsV2MarketABI);
export const getContractsByNetwork = (networkId, provider) => {
    return {
        Exchanger: ADDRESSES.Exchanger[networkId]
            ? Exchanger__factory.connect(ADDRESSES.Exchanger[networkId], provider)
            : undefined,
        ExchangeRates: ADDRESSES.ExchangeRates[networkId]
            ? ExchangeRates__factory.connect(ADDRESSES.ExchangeRates[networkId], provider)
            : undefined,
        SystemStatus: ADDRESSES.SystemStatus[networkId]
            ? SystemStatus__factory.connect(ADDRESSES.SystemStatus[networkId], provider)
            : undefined,
        SynthUtil: ADDRESSES.SynthUtil[networkId]
            ? SynthUtil__factory.connect(ADDRESSES.SynthUtil[networkId], provider)
            : undefined,
        SystemSettings: ADDRESSES.SystemSettings[networkId]
            ? SystemSettings__factory.connect(ADDRESSES.SystemSettings[networkId], provider)
            : undefined,
        SynthRedeemer: ADDRESSES.SynthRedeemer[networkId]
            ? SynthRedeemer__factory.connect(ADDRESSES.SynthRedeemer[networkId], provider)
            : undefined,
        FuturesMarketData: ADDRESSES.FuturesMarketData[networkId]
            ? FuturesMarketData__factory.connect(ADDRESSES.FuturesMarketData[networkId], provider)
            : undefined,
        PerpsV2MarketData: ADDRESSES.PerpsV2MarketData[networkId]
            ? PerpsV2MarketData__factory.connect(ADDRESSES.PerpsV2MarketData[networkId], provider)
            : undefined,
        PerpsV2MarketSettings: ADDRESSES.PerpsV2MarketSettings[networkId]
            ? PerpsV2MarketSettings__factory.connect(ADDRESSES.PerpsV2MarketSettings[networkId], provider)
            : undefined,
        Pyth: ADDRESSES.Pyth[networkId]
            ? Pyth__factory.connect(ADDRESSES.Pyth[networkId], provider)
            : undefined,
        FuturesMarketSettings: ADDRESSES.FuturesMarketSettings[networkId]
            ? FuturesMarketSettings__factory.connect(ADDRESSES.FuturesMarketSettings[networkId], provider)
            : undefined,
        Synthetix: ADDRESSES.Synthetix[networkId]
            ? Synthetix__factory.connect(ADDRESSES.Synthetix[networkId], provider)
            : undefined,
        SynthSwap: ADDRESSES.SynthSwap[networkId]
            ? SynthSwap__factory.connect(ADDRESSES.SynthSwap[networkId], provider)
            : undefined,
        cfUSD: ADDRESSES.cfUSD[networkId]
            ? ERC20__factory.connect(ADDRESSES.cfUSD[networkId], provider)
            : undefined,
        USDC: ADDRESSES.USDC[networkId]
            ? ERC20__factory.connect(ADDRESSES.USDC[networkId], provider)
            : undefined,
        USDT: ADDRESSES.USDT[networkId]
            ? ERC20__factory.connect(ADDRESSES.USDT[networkId], provider)
            : undefined,
        DAI: ADDRESSES.DAI[networkId]
            ? ERC20__factory.connect(ADDRESSES.DAI[networkId], provider)
            : undefined,
        LUSD: ADDRESSES.LUSD[networkId]
            ? ERC20__factory.connect(ADDRESSES.LUSD[networkId], provider)
            : undefined,
        SNXUSD: ADDRESSES.SNXUSD[networkId]
            ? ERC20__factory.connect(ADDRESSES.SNXUSD[networkId], provider)
            : undefined,
        SmartMarginAccountFactory: ADDRESSES.SmartMarginAccountFactory[networkId]
            ? SmartMarginAccountFactory__factory.connect(ADDRESSES.SmartMarginAccountFactory[networkId], provider)
            : undefined,
        // TODO: Replace these when we move away from wagmi hooks
        KwentaArrakisVault: ADDRESSES.KwentaArrakisVault[networkId]
            ? KwentaArrakisVault__factory.connect(ADDRESSES.KwentaArrakisVault[networkId], provider)
            : undefined,
        StakingRewards: ADDRESSES.StakingRewards[networkId]
            ? StakingRewards__factory.connect(ADDRESSES.StakingRewards[networkId], provider)
            : undefined,
        RewardEscrow: ADDRESSES.RewardEscrow[networkId]
            ? RewardEscrow__factory.connect(ADDRESSES.RewardEscrow[networkId], provider)
            : undefined,
        KwentaToken: ADDRESSES.KwentaToken[networkId]
            ? ERC20__factory.connect(ADDRESSES.KwentaToken[networkId], provider)
            : undefined,
        SupplySchedule: ADDRESSES.SupplySchedule[networkId]
            ? SupplySchedule__factory.connect(ADDRESSES.SupplySchedule[networkId], provider)
            : undefined,
        vKwentaToken: ADDRESSES.vKwentaToken[networkId]
            ? ERC20__factory.connect(ADDRESSES.vKwentaToken[networkId], provider)
            : undefined,
        MultipleMerkleDistributor: ADDRESSES.TradingRewards[networkId]
            ? MultipleMerkleDistributor__factory.connect(ADDRESSES.TradingRewards[networkId], provider)
            : undefined,
        MultipleMerkleDistributorPerpsV2: ADDRESSES.TradingRewardsPerpsV2[networkId]
            ? MultipleMerkleDistributorPerpsV2__factory.connect(ADDRESSES.TradingRewardsPerpsV2[networkId], provider)
            : undefined,
        MultipleMerkleDistributorStakingV2: ADDRESSES.TradingRewardsStakingV2[networkId]
            ? MultipleMerkleDistributorPerpsV2__factory.connect(ADDRESSES.TradingRewardsStakingV2[networkId], provider)
            : undefined,
        MultipleMerkleDistributorOp: ADDRESSES.OpRewards[networkId]
            ? MultipleMerkleDistributorOp__factory.connect(ADDRESSES.OpRewards[networkId], provider)
            : undefined,
        MultipleMerkleDistributorSnxOp: ADDRESSES.SnxOpRewards[networkId]
            ? MultipleMerkleDistributorOp__factory.connect(ADDRESSES.SnxOpRewards[networkId], provider)
            : undefined,
        BatchClaimer: ADDRESSES.BatchClaimer[networkId]
            ? BatchClaimer__factory.connect(ADDRESSES.BatchClaimer[networkId], provider)
            : undefined,
        veKwentaToken: ADDRESSES.veKwentaToken[networkId]
            ? ERC20__factory.connect(ADDRESSES.veKwentaToken[networkId], provider)
            : undefined,
        KwentaStakingRewards: ADDRESSES.KwentaStakingRewards[networkId]
            ? KwentaStakingRewards__factory.connect(ADDRESSES.KwentaStakingRewards[networkId], provider)
            : undefined,
        vKwentaRedeemer: ADDRESSES.vKwentaRedeemer[networkId]
            ? VKwentaRedeemer__factory.connect(ADDRESSES.vKwentaRedeemer[networkId], provider)
            : undefined,
        veKwentaRedeemer: ADDRESSES.veKwentaRedeemer[networkId]
            ? VeKwentaRedeemer__factory.connect(ADDRESSES.veKwentaRedeemer[networkId], provider)
            : undefined,
        KwentaStakingRewardsV2: ADDRESSES.KwentaStakingRewardsV2[networkId]
            ? KwentaStakingRewardsV2__factory.connect(ADDRESSES.KwentaStakingRewardsV2[networkId], provider)
            : undefined,
        RewardEscrowV2: ADDRESSES.RewardEscrowV2[networkId]
            ? RewardEscrowV2__factory.connect(ADDRESSES.RewardEscrowV2[networkId], provider)
            : undefined,
        perpsV3MarketProxy: ADDRESSES.PerpsV3MarketProxy[networkId]
            ? PerpsV3MarketProxy__factory.connect(ADDRESSES.PerpsV3MarketProxy[networkId], provider)
            : undefined,
        perpsV3AccountProxy: ADDRESSES.PerpsV3AccountProxy[networkId]
            ? PerpsV3AccountProxy__factory.connect(ADDRESSES.PerpsV3AccountProxy[networkId], provider)
            : undefined,
        EscrowMigrator: ADDRESSES.EscrowMigrator[networkId]
            ? EscrowMigrator__factory.connect(ADDRESSES.EscrowMigrator[networkId], provider)
            : undefined,
        BoostNft: ADDRESSES.BoostNft[networkId]
            ? BoostNFT__factory.connect(ADDRESSES.BoostNft[networkId], provider)
            : undefined,
    };
};
export const getMulticallContractsByNetwork = (networkId) => {
    return {
        SynthRedeemer: ADDRESSES.SynthRedeemer[networkId]
            ? new EthCallContract(ADDRESSES.SynthRedeemer[networkId], SynthRedeemerABI)
            : undefined,
        SynthUtil: ADDRESSES.SynthUtil[networkId]
            ? new EthCallContract(ADDRESSES.SynthUtil[networkId], SynthUtilABI)
            : undefined,
        ExchangeRates: ADDRESSES.ExchangeRates[networkId]
            ? new EthCallContract(ADDRESSES.ExchangeRates[networkId], ExchangeRatesABI)
            : undefined,
        FuturesMarketData: ADDRESSES.FuturesMarketData[networkId]
            ? new EthCallContract(ADDRESSES.FuturesMarketData[networkId], FuturesMarketDataABI)
            : undefined,
        FuturesMarketSettings: ADDRESSES.FuturesMarketSettings[networkId]
            ? new EthCallContract(ADDRESSES.FuturesMarketSettings[networkId], FuturesMarketSettingsABI)
            : undefined,
        PerpsV2MarketData: ADDRESSES.PerpsV2MarketData[networkId]
            ? new EthCallContract(ADDRESSES.PerpsV2MarketData[networkId], PerpsV2MarketDataABI)
            : undefined,
        PerpsV2MarketSettings: ADDRESSES.PerpsV2MarketSettings[networkId]
            ? new EthCallContract(ADDRESSES.PerpsV2MarketSettings[networkId], PerpsV2MarketSettingsABI)
            : undefined,
        StakingRewards: ADDRESSES.StakingRewards[networkId]
            ? new EthCallContract(ADDRESSES.StakingRewards[networkId], StakingRewardsABI)
            : undefined,
        KwentaArrakisVault: ADDRESSES.KwentaArrakisVault[networkId]
            ? new EthCallContract(ADDRESSES.KwentaArrakisVault[networkId], KwentaArrakisVaultABI)
            : undefined,
        RewardEscrow: ADDRESSES.RewardEscrow[networkId]
            ? new EthCallContract(ADDRESSES.RewardEscrow[networkId], RewardEscrowABI)
            : undefined,
        KwentaStakingRewards: ADDRESSES.KwentaStakingRewards[networkId]
            ? new EthCallContract(ADDRESSES.KwentaStakingRewards[networkId], KwentaStakingRewardsABI)
            : undefined,
        KwentaToken: ADDRESSES.KwentaToken[networkId]
            ? new EthCallContract(ADDRESSES.KwentaToken[networkId], ERC20ABI)
            : undefined,
        MultipleMerkleDistributor: ADDRESSES.TradingRewards[networkId]
            ? new EthCallContract(ADDRESSES.TradingRewards[networkId], MultipleMerkleDistributorABI)
            : undefined,
        MultipleMerkleDistributorPerpsV2: ADDRESSES.TradingRewardsPerpsV2[networkId]
            ? new EthCallContract(ADDRESSES.TradingRewardsPerpsV2[networkId], MultipleMerkleDistributorPerpsV2ABI)
            : undefined,
        MultipleMerkleDistributorStakingV2: ADDRESSES.TradingRewardsStakingV2[networkId]
            ? new EthCallContract(ADDRESSES.TradingRewardsStakingV2[networkId], MultipleMerkleDistributorPerpsV2ABI)
            : undefined,
        MultipleMerkleDistributorOp: ADDRESSES.OpRewards[networkId]
            ? new EthCallContract(ADDRESSES.OpRewards[networkId], MultipleMerkleDistributorOpABI)
            : undefined,
        MultipleMerkleDistributorSnxOp: ADDRESSES.SnxOpRewards[networkId]
            ? new EthCallContract(ADDRESSES.SnxOpRewards[networkId], MultipleMerkleDistributorOpABI)
            : undefined,
        vKwentaToken: ADDRESSES.vKwentaToken[networkId]
            ? new EthCallContract(ADDRESSES.vKwentaToken[networkId], ERC20ABI)
            : undefined,
        veKwentaToken: ADDRESSES.veKwentaToken[networkId]
            ? new EthCallContract(ADDRESSES.veKwentaToken[networkId], ERC20ABI)
            : undefined,
        SupplySchedule: ADDRESSES.SupplySchedule[networkId]
            ? new EthCallContract(ADDRESSES.SupplySchedule[networkId], SupplyScheduleABI)
            : undefined,
        SystemStatus: ADDRESSES.SystemStatus[networkId]
            ? new EthCallContract(ADDRESSES.SystemStatus[networkId], SystemStatusABI)
            : undefined,
        DappMaintenance: ADDRESSES.DappMaintenance[networkId]
            ? new EthCallContract(ADDRESSES.DappMaintenance[networkId], DappMaintenanceABI)
            : undefined,
        SNXUSD: ADDRESSES.SNXUSD[networkId]
            ? new EthCallContract(ADDRESSES.SNXUSD[networkId], ERC20ABI)
            : undefined,
        KwentaStakingRewardsV2: ADDRESSES.KwentaStakingRewardsV2[networkId]
            ? new EthCallContract(ADDRESSES.KwentaStakingRewardsV2[networkId], KwentaStakingRewardsV2ABI)
            : undefined,
        RewardEscrowV2: ADDRESSES.RewardEscrowV2[networkId]
            ? new EthCallContract(ADDRESSES.RewardEscrowV2[networkId], RewardEscrowV2ABI)
            : undefined,
        perpsV3MarketProxy: ADDRESSES.PerpsV3MarketProxy[networkId]
            ? new EthCallContract(ADDRESSES.PerpsV3MarketProxy[networkId], PerpsV3MarketProxyABI)
            : undefined,
        perpsV3AccountProxy: ADDRESSES.PerpsV3AccountProxy[networkId]
            ? new EthCallContract(ADDRESSES.PerpsV3AccountProxy[networkId], PerpsV3AccountProxyABI)
            : undefined,
        EscrowMigrator: ADDRESSES.EscrowMigrator[networkId]
            ? new EthCallContract(ADDRESSES.EscrowMigrator[networkId], EscrowMigratorABI)
            : undefined,
        BoostNft: ADDRESSES.BoostNft[networkId]
            ? new EthCallContract(ADDRESSES.BoostNft[networkId], BoostNftABI)
            : undefined,
        cfUSD: ADDRESSES.cfUSD[networkId]
            ? new EthCallContract(ADDRESSES.cfUSD[networkId], ERC20ABI)
            : undefined,
        USDC: ADDRESSES.USDC[networkId]
            ? new EthCallContract(ADDRESSES.USDC[networkId], ERC20ABI)
            : undefined,
        USDT: ADDRESSES.USDT[networkId]
            ? new EthCallContract(ADDRESSES.USDT[networkId], ERC20ABI)
            : undefined,
        DAI: ADDRESSES.DAI[networkId]
            ? new EthCallContract(ADDRESSES.DAI[networkId], ERC20ABI)
            : undefined,
        LUSD: ADDRESSES.LUSD[networkId]
            ? new EthCallContract(ADDRESSES.LUSD[networkId], ERC20ABI)
            : undefined,
    };
};
