var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PERMIT2_ADDRESS } from '../constants';
import { AllowanceProvider, MaxUint48, MaxUint160, AllowanceTransfer, } from '@uniswap/permit2-sdk';
const getPermit2Nonce = (provider, owner, token, spender) => __awaiter(void 0, void 0, void 0, function* () {
    const allowanceProvider = new AllowanceProvider(provider, PERMIT2_ADDRESS);
    const allowance = yield allowanceProvider.getAllowanceData(token, owner, spender);
    return allowance.nonce;
});
const getPermit2Amount = (provider, owner, token, spender) => __awaiter(void 0, void 0, void 0, function* () {
    const allowanceProvider = new AllowanceProvider(provider, PERMIT2_ADDRESS);
    const allowance = yield allowanceProvider.getAllowanceData(token, owner, spender);
    return allowance.amount;
});
const getPermit2TypedData = (provider, tokenAddress, owner, spender, amount, deadline) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const chainId = (yield provider.getNetwork()).chainId;
    const details = {
        token: tokenAddress,
        amount: (_a = amount === null || amount === void 0 ? void 0 : amount.toHexString()) !== null && _a !== void 0 ? _a : MaxUint160.toHexString(),
        expiration: (_b = deadline === null || deadline === void 0 ? void 0 : deadline.toHexString()) !== null && _b !== void 0 ? _b : MaxUint48.toHexString(),
        nonce: yield getPermit2Nonce(provider, owner, tokenAddress, spender),
    };
    const message = {
        details,
        spender,
        sigDeadline: (_c = deadline === null || deadline === void 0 ? void 0 : deadline.toHexString()) !== null && _c !== void 0 ? _c : MaxUint48.toHexString(),
    };
    return AllowanceTransfer.getPermitData(message, PERMIT2_ADDRESS, chainId);
});
export { getPermit2TypedData, getPermit2Amount };
