var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigNumber } from '@ethersproject/bignumber';
import { formatBytes32String } from '@ethersproject/strings';
// @ts-ignore TODO: remove once types are added
import { wei } from '@synthetixio/wei';
import axios from 'axios';
import { Contract as EthCallContract } from 'ethcall';
import { ethers } from 'ethers';
import { get, keyBy } from 'lodash';
import { formatEther, parseEther } from 'ethers/lib/utils';
import moment from 'moment';
import * as sdkErrors from '../common/errors';
import { getEthGasPrice } from '../common/gas';
import { ADDRESSES } from '../constants';
import { ADDITIONAL_MARKETS, ATOMIC_EXCHANGES_L1, ATOMIC_EXCHANGE_SLIPPAGE, CG_BASE_API_URL, CRYPTO_CURRENCY_MAP, DEFAULT_1INCH_SLIPPAGE, DEFAULT_BUFFER, ETH_ADDRESS, ETH_COINGECKO_ADDRESS, KWENTA_REFERRAL_ADDRESS, PROTOCOLS, } from '../constants/exchange';
import { KWENTA_TRACKING_CODE } from '../constants/futures';
import { UNIT_BIG_NUM, ZERO_WEI } from '../constants/number';
import erc20Abi from '../contracts/abis/ERC20.json';
import { getSynthsForNetwork, synths } from '../data/synths';
import { queryWalletTrades } from '../queries/exchange';
import { synthToAsset } from '../utils/exchange';
import { getMainEndpoint } from '../utils/futures';
import { getProxySynthSymbol, getReasonFromCode } from '../utils/synths';
import { getTransactionPrice, normalizeGasLimit } from '../utils/transactions';
export default class ExchangeService {
    constructor(sdk) {
        this.tokensMap = {};
        this.tokenList = [];
        this.sdk = sdk;
    }
    get exchangeRates() {
        return this.sdk.prices.currentPrices.onChain;
    }
    /**
     * @desc - Get the provider to be used for transactions on a currency pair.
     * @param fromCurrencyKey The currency key of the source token.
     * @param toCurrencyKey The currency key of the destination token.
     * @returns Returns one of '1inch', 'synthetix', or 'synthswap'.
     */
    getTxProvider(fromCurrencyKey, toCurrencyKey) {
        // if (!toCurrencyKey || !fromCurrencyKey) return undefined
        // if (
        // 	this.synthsMap?.[toCurrencyKey as SynthSymbol] &&
        // 	this.synthsMap?.[fromCurrencyKey as SynthSymbol]
        // ) {
        // 	return 'synthetix'
        // }
        // if (this.tokensMap[toCurrencyKey] && this.tokensMap[fromCurrencyKey] && false) {
        // 	return '1inch'
        // }
        return 'synthswap';
    }
    getTradePrices(txProvider, fromCurrencyKey, toCurrencyKey, fromAmount, toAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            const coinGeckoPrices = yield this.getCoingeckoPrices(fromCurrencyKey, toCurrencyKey);
            const [quotePriceRate, basePriceRate] = yield Promise.all([fromCurrencyKey, toCurrencyKey].map((currencyKey) => this.getPriceRate(currencyKey, txProvider, coinGeckoPrices)));
            let quoteTradePrice = fromAmount.mul(quotePriceRate || 0);
            let baseTradePrice = toAmount.mul(basePriceRate || 0);
            if (this.sUSDRate) {
                quoteTradePrice = quoteTradePrice.div(this.sUSDRate);
                baseTradePrice = baseTradePrice.div(this.sUSDRate);
            }
            return { quoteTradePrice, baseTradePrice };
        });
    }
    getSlippagePercent(fromCurrencyKey, toCurrencyKey, fromAmount, toAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            const txProvider = this.getTxProvider(fromCurrencyKey, toCurrencyKey);
            if (txProvider === '1inch') {
                const { quoteTradePrice: totalTradePrice, baseTradePrice: estimatedBaseTradePrice } = yield this.getTradePrices(txProvider, fromCurrencyKey, toCurrencyKey, fromAmount, toAmount);
                if (totalTradePrice.gt(0) && estimatedBaseTradePrice.gt(0)) {
                    return totalTradePrice.sub(estimatedBaseTradePrice).div(totalTradePrice).neg();
                }
            }
            return undefined;
        });
    }
    getBaseFeeRate(fromCurrencyKey, toCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.SystemSettings) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const [sourceCurrencyFeeRate, destinationCurrencyFeeRate] = yield Promise.all([
                this.sdk.context.contracts.SystemSettings.exchangeFeeRate(ethers.utils.formatBytes32String(toCurrencyKey)),
                this.sdk.context.contracts.SystemSettings.exchangeFeeRate(ethers.utils.formatBytes32String(fromCurrencyKey)),
            ]);
            return sourceCurrencyFeeRate && destinationCurrencyFeeRate
                ? wei(sourceCurrencyFeeRate.add(destinationCurrencyFeeRate))
                : wei(0);
        });
    }
    /**
     * @desc - Get the fee rate for exchanging between two currencies.
     * @param fromCurrencyKey The currency key of the source token.
     * @param toCurrencyKey The currency key of the destination token.
     * @returns Returns the fee rate.
     */
    getExchangeFeeRate(fromCurrencyKey, toCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.Exchanger) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const exchangeFeeRate = yield this.sdk.context.contracts.Exchanger.feeRateForExchange(ethers.utils.formatBytes32String(fromCurrencyKey), ethers.utils.formatBytes32String(toCurrencyKey));
            return exchangeFeeRate;
        });
    }
    getRate(fromCurrencyKey, toCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCoingeckoPrices(fromCurrencyKey, toCurrencyKey);
            const base = response[toCurrencyKey];
            const quote = response[fromCurrencyKey];
            const floatingPointNumber = parseFloat(formatEther(quote)) / parseFloat(formatEther(base));
            return floatingPointNumber;
        });
    }
    /**
     * @desc Get the list of whitelisted tokens on 1inch.
     * @returns Returns the list of tokens currently whitelisted on 1inch.
     */
    getOneInchTokenList() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield axios.get(this.oneInchApiUrl + 'tokens');
            const tokensMap = response.data.tokens || {};
            const chainId = this.sdk.context.isL2 ? 10 : 1;
            const tokens = Object.values(tokensMap).map((t) => (Object.assign(Object.assign({}, t), { chainId, tags: [] })));
            return {
                tokens,
                tokensMap: keyBy(tokens, 'symbol'),
                symbols: tokens.map((token) => token.symbol),
            };
        });
    }
    getFeeReclaimPeriod(currencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.Exchanger) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const maxSecsLeftInWaitingPeriod = (yield this.sdk.context.contracts.Exchanger.maxSecsLeftInWaitingPeriod(this.sdk.context.walletAddress, ethers.utils.formatBytes32String(currencyKey)));
            return Number(maxSecsLeftInWaitingPeriod);
        });
    }
    swapSynthTokens(fromToken, toToken, fromAmount) {
        return __awaiter(this, void 0, void 0, function* () { });
    }
    swapSynthSwapInto(fromToken, toToken, fromAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            const SynthSwap = this.sdk.context.contracts.SynthSwap;
            if (!SynthSwap) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const finalAmount = parseFloat(fromAmount);
            try {
                const functionSignature = 'exactInputSingle((address,address,uint24,address,uint256,uint256,uint256,uint160))';
                const abiCoder = new ethers.utils.AbiCoder();
                const encodedData = abiCoder.encode(['tuple(address,address,uint24,address,uint256,uint256,uint256,uint160)'], [
                    [
                        this.getSynthTokenAddress(fromToken),
                        this.getSynthTokenAddress('cfUSD'),
                        3000,
                        ADDRESSES.SynthSwap[this.sdk.context.networkId],
                        moment().add(10, 'm').valueOf(),
                        ethers.utils.parseEther(fromAmount),
                        0,
                        0,
                    ],
                ]);
                const functionSelector = ethers.utils
                    .keccak256(ethers.utils.toUtf8Bytes(functionSignature))
                    .slice(0, 10);
                const fullEncodedData = functionSelector + encodedData.slice(2);
                const response = yield this.sdk.transactions.createContractTxn(SynthSwap, 'uniswapSwapInto', [
                    formatBytes32String(toToken),
                    this.getSynthTokenAddress(fromToken),
                    parseEther(finalAmount.toString()),
                    fullEncodedData,
                ]);
                return response;
            }
            catch (error) {
                return error;
            }
        });
    }
    swapSynthSwapOutOf(fromToken, toToken, fromAmount, expectedAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            const SynthSwap = this.sdk.context.contracts.SynthSwap;
            if (!SynthSwap) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const finalAmount = parseFloat(fromAmount);
            try {
                const functionSignature = 'exactInputSingle((address,address,uint24,address,uint256,uint256,uint256,uint160))';
                const abiCoder = new ethers.utils.AbiCoder();
                const encodedData = abiCoder.encode(['tuple(address,address,uint24,address,uint256,uint256,uint256,uint160)'], [
                    [
                        this.getSynthTokenAddress('cfUSD'),
                        this.getSynthTokenAddress(toToken),
                        3000,
                        ADDRESSES.SynthSwap[this.sdk.context.networkId],
                        moment().add(10, 'm').valueOf(),
                        ethers.utils.parseEther(expectedAmount),
                        0,
                        0,
                    ],
                ]);
                const functionSelector = ethers.utils
                    .keccak256(ethers.utils.toUtf8Bytes(functionSignature))
                    .slice(0, 10);
                const fullEncodedData = functionSelector + encodedData.slice(2);
                const response = yield this.sdk.transactions.createContractTxn(SynthSwap, 'uniswapSwapOutOf', [
                    formatBytes32String(fromToken),
                    this.getSynthTokenAddress(toToken),
                    parseEther(finalAmount.toString()),
                    parseEther(parseFloat(expectedAmount).toString()),
                    fullEncodedData,
                ]);
                return response;
            }
            catch (error) {
                return error;
            }
        });
    }
    swapSynths(fromToken, toToken, fromAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            const Synthetix = this.sdk.context.contracts.Synthetix;
            if (!Synthetix) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const finalAmount = parseFloat(fromAmount);
            try {
                const response = yield this.sdk.transactions.createContractTxn(Synthetix, 'executeExchange', [
                    formatBytes32String(fromToken),
                    parseEther(finalAmount.toString()),
                    formatBytes32String(toToken),
                ]);
                return response;
            }
            catch (error) {
                return error;
            }
        });
    }
    swapOneInchMeta(fromTokenAddress, toTokenAddress, amount, fromTokenDecimals) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = yield this.getOneInchSwapParams(fromTokenAddress, toTokenAddress, amount, fromTokenDecimals);
            const { from, to, data, value } = params.tx;
            return this.sdk.context.signer.populateTransaction({
                from,
                to,
                data,
                value: ethers.BigNumber.from(value),
            });
        });
    }
    swapOneInch(fromTokenAddress, toTokenAddress, amount, fromTokenDecimals) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = yield this.getOneInchSwapParams(fromTokenAddress, toTokenAddress, amount, fromTokenDecimals);
            const { from, to, data, value } = params.tx;
            return this.sdk.transactions.createEVMTxn({ from, to, data, value });
        });
    }
    swapOneInchGasEstimate(fromTokenAddress, toTokenAddress, amount, fromTokenDecimals) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = yield this.getOneInchSwapParams(fromTokenAddress, toTokenAddress, amount, fromTokenDecimals);
            return params.tx.gas;
        });
    }
    getNumEntries(currencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.Exchanger) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { numEntries } = yield this.sdk.context.contracts.Exchanger.settlementOwing(this.sdk.context.walletAddress, ethers.utils.formatBytes32String(currencyKey));
            return numEntries ? Number(numEntries.toString()) : 0;
        });
    }
    getAtomicRates(currencyKey) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.ExchangeRates) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { value } = yield this.sdk.context.contracts.ExchangeRates.effectiveAtomicValueAndRates(ethers.utils.formatBytes32String(currencyKey), UNIT_BIG_NUM, ethers.utils.formatBytes32String('cfUSD'));
            return (_a = wei(value)) !== null && _a !== void 0 ? _a : wei(0);
        });
    }
    approveSwap(fromCurrencyKey, toCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const txProvider = this.getTxProvider(fromCurrencyKey, toCurrencyKey);
            const fromCurrencyContract = this.getSynthContract(fromCurrencyKey);
            const approveAddress = yield this.getApproveAddress(txProvider);
            if (fromCurrencyContract) {
                const { hash } = yield this.sdk.transactions.createContractTxn(fromCurrencyContract, 'approve', [approveAddress, ethers.constants.MaxUint256]);
                return hash;
            }
            return undefined;
        });
    }
    handleSettle(toCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.isL2) {
                throw new Error(sdkErrors.REQUIRES_L2);
            }
            const numEntries = yield this.getNumEntries(toCurrencyKey);
            if (numEntries > 12) {
                const destinationCurrencyKey = ethers.utils.formatBytes32String(toCurrencyKey);
                const { hash } = yield this.sdk.transactions.createSynthetixTxn('Exchanger', 'settle', [
                    this.sdk.context.walletAddress,
                    destinationCurrencyKey,
                ]);
                return hash;
            }
            return undefined;
        });
    }
    // TODO: Refactor handleExchange
    handleExchange(fromCurrencyKey, toCurrencyKey, fromAmount, toAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            let tx = null;
            // const isNotSynthKeys = ['TKN']
            // const isNotSynth = isNotSynthKeys.includes(fromCurrencyKey)
            // const isOnlySynth =
            // 	SYNTH_TOKENS.includes(fromCurrencyKey) && SYNTH_TOKENS.includes(toCurrencyKey)
            // if (isOnlySynth) {
            tx = yield this.swapSynths(fromCurrencyKey, toCurrencyKey, fromAmount);
            // } else {
            // 	if (isNotSynth) {
            // 		tx = await this.swapSynthSwapInto(fromCurrencyKey, toCurrencyKey, fromAmount)
            // 	} else {
            // 		tx = await this.swapSynthSwapOutOf(fromCurrencyKey, toCurrencyKey, fromAmount, toAmount)
            // 	}
            // }
            // } else {
            // 	const isAtomic = this.checkIsAtomic(fromCurrencyKey, toCurrencyKey)
            // 	const exchangeParams = this.getExchangeParams(
            // 		fromCurrencyKey,
            // 		toCurrencyKey,
            // 		wei(fromAmount),
            // 		wei(toAmount).mul(wei(1).sub(ATOMIC_EXCHANGE_SLIPPAGE)),
            // 		isAtomic
            // 	)
            // 	const shouldExchange =
            // 		!!exchangeParams &&
            // 		!!this.sdk.context.walletAddress &&
            // 		!!this.sdk.context.contracts.Synthetix
            // 	if (shouldExchange) {
            // 		const { hash } = await this.sdk.transactions.createSynthetixTxn(
            // 			'Synthetix',
            // 			isAtomic ? 'exchangeAtomically' : 'exchangeWithTracking',
            // 			exchangeParams
            // 		)
            // 		return hash
            // 	}
            // }
            return tx === null || tx === void 0 ? void 0 : tx.hash;
        });
    }
    getTransactionFee(fromCurrencyKey, toCurrencyKey, fromAmount, toAmount) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const gasPrices = yield getEthGasPrice(this.sdk.context.networkId, this.sdk.context.provider);
            const txProvider = this.getTxProvider(fromCurrencyKey, toCurrencyKey);
            const ethPriceRate = this.getExchangeRatesForCurrencies(this.exchangeRates, 'sETH', 'cfUSD');
            const gasPrice = gasPrices.fast;
            if (txProvider === 'synthswap') {
                const gasInfo = yield this.getGasEstimateForExchange(txProvider, fromCurrencyKey, toCurrencyKey, fromAmount);
                return getTransactionPrice(gasPrice, BigNumber.from((gasInfo === null || gasInfo === void 0 ? void 0 : gasInfo.limit) || 0), ethPriceRate, (_a = gasInfo === null || gasInfo === void 0 ? void 0 : gasInfo.l1Fee) !== null && _a !== void 0 ? _a : ZERO_WEI);
            }
            else {
                if (!this.sdk.context.contracts.Synthetix) {
                    throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
                }
                const isAtomic = this.checkIsAtomic(fromCurrencyKey, toCurrencyKey);
                const exchangeParams = this.getExchangeParams(fromCurrencyKey, toCurrencyKey, wei(fromAmount || 0), wei(toAmount || 0).mul(wei(1).sub(ATOMIC_EXCHANGE_SLIPPAGE)), isAtomic);
                const method = isAtomic ? 'exchangeAtomically' : 'exchangeWithTracking';
                const txn = {
                    to: ADDRESSES.Synthetix[1],
                    data: this.sdk.context.contracts.Synthetix.interface.encodeFunctionData(
                    // @ts-ignore TODO: Fix types
                    method, exchangeParams),
                    value: ethers.BigNumber.from(0),
                };
                const [baseGasLimit, optimismLayerOneFee] = yield Promise.all([
                    this.sdk.transactions.estimateGas(txn),
                    this.sdk.transactions.getOptimismLayerOneFees(txn),
                ]);
                const gasLimit = wei(baseGasLimit !== null && baseGasLimit !== void 0 ? baseGasLimit : 0, 9)
                    .mul(1 + DEFAULT_BUFFER)
                    .toBN();
                return getTransactionPrice(gasPrice, gasLimit, ethPriceRate, optimismLayerOneFee);
            }
        });
    }
    getTransferableSFCXBalance() {
        return __awaiter(this, void 0, void 0, function* () {
            const Synthetix = this.sdk.context.contracts.Synthetix;
            if (!Synthetix || !this.sdk.context.walletAddress) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const response = yield Synthetix.transferableSynDex(this.sdk.context.walletAddress);
            return response;
        });
    }
    getSFCXPrice() {
        return __awaiter(this, void 0, void 0, function* () {
            const ExchangeRates = this.sdk.context.contracts.ExchangeRates;
            if (!ExchangeRates || !this.sdk.context.walletAddress) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const response = yield ExchangeRates.rateAndInvalid(formatBytes32String('SFCX'));
            return response[0];
        });
    }
    getDebtBalance(currencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const Synthetix = this.sdk.context.contracts.Synthetix;
            if (!Synthetix || !this.sdk.context.walletAddress) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const response = yield Synthetix.debtBalanceOf(this.sdk.context.walletAddress, formatBytes32String(currencyKey));
            return response;
        });
    }
    getCollateralisationRatio() {
        return __awaiter(this, void 0, void 0, function* () {
            const Synthetix = this.sdk.context.contracts.Synthetix;
            if (!Synthetix || !this.sdk.context.walletAddress) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const response = yield Synthetix.collateralisationRatio(this.sdk.context.walletAddress);
            return response;
        });
    }
    getCollateral() {
        return __awaiter(this, void 0, void 0, function* () {
            const Synthetix = this.sdk.context.contracts.Synthetix;
            if (!Synthetix || !this.sdk.context.walletAddress) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const response = yield Synthetix.collateral(this.sdk.context.walletAddress);
            return response;
        });
    }
    getCFUSDBalance() {
        return __awaiter(this, void 0, void 0, function* () {
            const cfUSD = this.sdk.context.contracts.cfUSD;
            if (!cfUSD || !this.sdk.context.walletAddress) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const response = yield cfUSD.balanceOf(this.sdk.context.walletAddress);
            return response;
        });
    }
    createSynths(cfUSD) {
        return __awaiter(this, void 0, void 0, function* () {
            const Synthetix = this.sdk.context.contracts.Synthetix;
            if (!Synthetix || !this.sdk.context.walletAddress) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            try {
                const response = yield this.sdk.transactions.createContractTxn(Synthetix, 'createSynths', [
                    parseEther(cfUSD),
                ]);
                return response;
            }
            catch (error) {
                throw new Error(error);
            }
        });
    }
    burnSynths(cfUSD) {
        return __awaiter(this, void 0, void 0, function* () {
            const Synthetix = this.sdk.context.contracts.Synthetix;
            if (!Synthetix || !this.sdk.context.walletAddress) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            try {
                const response = yield this.sdk.transactions.createContractTxn(Synthetix, 'burnSynths', [
                    parseEther(cfUSD),
                ]);
                return response;
            }
            catch (error) {
                throw new Error(error);
            }
        });
    }
    burnToTarget() {
        return __awaiter(this, void 0, void 0, function* () {
            const Synthetix = this.sdk.context.contracts.Synthetix;
            if (!Synthetix || !this.sdk.context.walletAddress) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            try {
                const response = yield this.sdk.transactions.createContractTxn(Synthetix, 'burnSynthsToTarget', []);
                return response;
            }
            catch (error) {
                return error;
            }
        });
    }
    getFeeCost(fromCurrencyKey, toCurrencyKey, fromAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            const txProvider = this.getTxProvider(fromCurrencyKey, toCurrencyKey);
            const coinGeckoPrices = yield this.getCoingeckoPrices(fromCurrencyKey, toCurrencyKey);
            const [exchangeFeeRate, fromPriceRate] = yield Promise.all([
                this.getExchangeFeeRate(fromCurrencyKey, toCurrencyKey),
                this.getPriceRate(fromCurrencyKey, txProvider, coinGeckoPrices),
            ]);
            return wei(fromAmount).mul(exchangeFeeRate).mul(fromPriceRate);
        });
    }
    getApproveAddress(txProvider) {
        return ADDRESSES.SynthSwap[this.sdk.context.networkId];
    }
    checkAllowance(fromCurrencyKey, toCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const txProvider = this.getTxProvider(fromCurrencyKey, toCurrencyKey);
            const [fromCurrencyContract, approveAddress] = yield Promise.all([
                this.getSynthContract(fromCurrencyKey),
                this.getApproveAddress(txProvider),
            ]);
            if (!!fromCurrencyContract) {
                const allowance = (yield fromCurrencyContract.allowance(this.sdk.context.walletAddress, approveAddress));
                return wei(ethers.utils.formatEther(allowance));
            }
        });
    }
    getCurrencyName(currencyKey) {
        var _a, _b;
        return (_b = (_a = this.allTokensMap) === null || _a === void 0 ? void 0 : _a[currencyKey]) === null || _b === void 0 ? void 0 : _b.name;
    }
    getOneInchQuote(toCurrencyKey, fromCurrencyKey, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const cfUSD = this.tokensMap['cfUSD'];
            const decimals = this.getTokenDecimals(fromCurrencyKey);
            const fromTokenAddress = this.getTokenAddress(fromCurrencyKey);
            const toTokenAddress = this.getTokenAddress(toCurrencyKey);
            const txProvider = this.getTxProvider(fromCurrencyKey, toCurrencyKey);
            const synth = this.tokensMap[fromCurrencyKey] || this.tokensMap[toCurrencyKey];
            const synthUsdRate = synth ? this.getPairRates(synth, 'cfUSD') : null;
            if (!fromCurrencyKey || !toCurrencyKey || !cfUSD || !amount.length || wei(amount).eq(0)) {
                return '';
            }
            if (txProvider === '1inch') {
                const estimatedAmount = yield this.quoteOneInch(fromTokenAddress, toTokenAddress, amount, decimals);
                return estimatedAmount;
            }
            if (this.tokensMap[fromCurrencyKey]) {
                const usdAmount = wei(amount).div(synthUsdRate);
                const estimatedAmount = yield this.quoteOneInch(cfUSD.address, toTokenAddress, usdAmount.toString(), decimals);
                return estimatedAmount;
            }
            else {
                const estimatedAmount = yield this.quoteOneInch(fromTokenAddress, cfUSD.address, amount, decimals);
                return wei(estimatedAmount).mul(synthUsdRate).toString();
            }
        });
    }
    getPriceRate(currencyKey, txProvider, coinGeckoPrices) {
        return __awaiter(this, void 0, void 0, function* () {
            return coinGeckoPrices[currencyKey];
        });
    }
    getRedeemableDeprecatedSynths() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { SynthRedeemer } = this.sdk.context.contracts;
            const { SynthRedeemer: Redeemer } = this.sdk.context.multicallContracts;
            if (!SynthRedeemer || !Redeemer) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            const synthDeprecatedFilter = SynthRedeemer.filters.SynthDeprecated();
            const deprecatedSynthsEvents = yield SynthRedeemer.queryFilter(synthDeprecatedFilter);
            const deprecatedProxySynthsAddresses = (_a = deprecatedSynthsEvents.map((e) => { var _a; return (_a = e.args) === null || _a === void 0 ? void 0 : _a.synth; }).filter(Boolean)) !== null && _a !== void 0 ? _a : [];
            const calls = [];
            for (const addr of deprecatedProxySynthsAddresses) {
                calls.push(getProxySynthSymbol(addr));
                calls.push(Redeemer.balanceOf(addr, walletAddress));
            }
            const redeemableSynthData = (yield this.sdk.context.multicallProvider.all(calls));
            let totalUSDBalance = wei(0);
            const cryptoBalances = [];
            for (let i = 0; i < redeemableSynthData.length; i += 2) {
                const usdBalance = wei(redeemableSynthData[i + 1]);
                if (usdBalance.gt(0)) {
                    totalUSDBalance = totalUSDBalance.add(usdBalance);
                    cryptoBalances.push({
                        currencyKey: redeemableSynthData[i],
                        proxyAddress: deprecatedProxySynthsAddresses[i],
                        balance: wei(0),
                        usdBalance,
                    });
                }
            }
            return { balances: cryptoBalances, totalUSDBalance };
        });
    }
    validCurrencyKeys(fromCurrencyKey, toCurrencyKey) {
        return [fromCurrencyKey, toCurrencyKey].map((currencyKey) => {
            return (!!currencyKey &&
                (!!this.synthsMap[currencyKey] || !!this.tokensMap[currencyKey]));
        });
    }
    getCoingeckoPrices(fromCurrencyKey, toCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const { ExchangeRates } = this.sdk.context.multicallContracts;
            if (!ExchangeRates) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const [fromCurrencyRate, toCurrencyRate] = yield this.sdk.context.multicallProvider.all([
                ExchangeRates.rateAndInvalid(formatBytes32String(fromCurrencyKey)),
                ExchangeRates.rateAndInvalid(formatBytes32String(toCurrencyKey)),
            ]);
            return {
                [fromCurrencyKey]: fromCurrencyRate[0],
                [toCurrencyKey]: toCurrencyRate[0],
            };
        });
    }
    batchGetCoingeckoPrices(tokenAddresses, include24hrChange = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const platform = this.sdk.context.isL2 ? 'optimistic-ethereum' : 'ethereum';
            const response = yield axios.get(`${CG_BASE_API_URL}/simple/token_price/${platform}?contract_addresses=${tokenAddresses
                .join(',')
                .replace(ETH_ADDRESS, ETH_COINGECKO_ADDRESS)}&vs_currencies=usd${include24hrChange ? '&include_24hr_change=true' : ''}`);
            return response.data;
        });
    }
    get sUSDRate() {
        return this.exchangeRates['cfUSD'];
    }
    getExchangeParams(fromCurrencyKey, toCurrencyKey, sourceAmount, minAmount, isAtomic) {
        const sourceCurrencyKey = ethers.utils.formatBytes32String(fromCurrencyKey);
        const destinationCurrencyKey = ethers.utils.formatBytes32String(toCurrencyKey);
        const sourceAmountBN = sourceAmount.toBN();
        if (isAtomic) {
            return [
                sourceCurrencyKey,
                sourceAmountBN,
                destinationCurrencyKey,
                KWENTA_TRACKING_CODE,
                minAmount.toBN(),
            ];
        }
        else {
            return [
                sourceCurrencyKey,
                sourceAmountBN,
                destinationCurrencyKey,
                this.sdk.context.walletAddress,
                KWENTA_TRACKING_CODE,
            ];
        }
    }
    getSynthsMap() {
        return this.synthsMap;
    }
    get synthsMap() {
        return getSynthsForNetwork(this.sdk.context.networkId);
    }
    getOneInchTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            const { tokensMap, tokens } = yield this.getOneInchTokenList();
            this.tokensMap = tokensMap;
            this.tokenList = tokens;
            this.allTokensMap = Object.assign(Object.assign({}, this.synthsMap), tokensMap);
            return { tokensMap: this.tokensMap, tokenList: this.tokenList };
        });
    }
    getSynthSuspensions() {
        return __awaiter(this, void 0, void 0, function* () {
            const { SystemStatus } = this.sdk.context.multicallContracts;
            const synthsMap = this.sdk.exchange.getSynthsMap();
            if (!SystemStatus) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const calls = [];
            for (let synth in synthsMap) {
                calls.push(SystemStatus.synthExchangeSuspension(formatBytes32String(synth)));
            }
            const responses = (yield this.sdk.context.multicallProvider.all(calls));
            let ret = {};
            let i = 0;
            for (let synth in synthsMap) {
                const [isSuspended, reason] = responses[i];
                const reasonCode = Number(reason);
                ret[synth] = {
                    isSuspended: responses[i][0],
                    reasonCode,
                    reason: isSuspended ? getReasonFromCode(reasonCode) : null,
                };
                i++;
            }
            return ret;
        });
    }
    get mainGqlEndpoint() {
        return getMainEndpoint(this.sdk.context.networkId);
    }
    getWalletTrades() {
        return queryWalletTrades(this.sdk, this.sdk.context.walletAddress);
    }
    /**
     * Get token balances for the given wallet address
     * @param walletAddress Wallet address
     * @returns Token balances for the given wallet address
     */
    getTokenBalances(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            // return {}
            // DEPLOYMENT: Add tokens that you need to add support for
            const testTokens = [
                {
                    name: 'Token',
                    symbol: 'TKN',
                    address: '0x505B954533236F6d9b1BD7fd166878d33aDdaf94',
                    decimals: 18,
                    chainId: this.sdk.context.networkId,
                    logoURI: '',
                    tags: [''],
                },
            ];
            // if (!this.sdk.context.isMainnet) return {}
            // const filteredTokens: Token[] = []
            // const symbols: string[] = []
            // const tokensMap: Record<string, Token> = {}
            // this.tokenList.forEach((token) => {
            // 	if (!FILTERED_TOKENS.includes(token.address.toLowerCase())) {
            // 		symbols.push(token.symbol)
            // 		tokensMap[token.symbol] = token
            // 		filteredTokens.push(token)
            // 	}
            // })
            const calls = [];
            for (const { address, symbol } of testTokens) {
                if (symbol === CRYPTO_CURRENCY_MAP.ETH) {
                    calls.push(this.sdk.context.multicallProvider.getEthBalance(walletAddress));
                }
                else {
                    const tokenContract = new EthCallContract(address, erc20Abi);
                    calls.push(tokenContract.balanceOf(walletAddress));
                }
            }
            const data = (yield this.sdk.context.multicallProvider.all(calls));
            const tokenBalances = {};
            data.forEach((value, i) => {
                var _a;
                if (value.lte(0))
                    return;
                const token = testTokens[i];
                tokenBalances[testTokens[i].symbol] = {
                    balance: wei(value, (_a = token.decimals) !== null && _a !== void 0 ? _a : 18),
                    token,
                };
            });
            return tokenBalances;
        });
    }
    checkIsAtomic(fromCurrencyKey, toCurrencyKey) {
        if (this.sdk.context.isL2 || !toCurrencyKey || !fromCurrencyKey) {
            return false;
        }
        return [toCurrencyKey, fromCurrencyKey].every((currency) => ATOMIC_EXCHANGES_L1.includes(currency));
    }
    getTokenDecimals(currencyKey) {
        return get(this.allTokensMap, [currencyKey, 'decimals'], undefined);
    }
    getCurrencyContract(currencyKey) {
        if (this.allTokensMap[currencyKey]) {
            const tokenAddress = this.getTokenAddress(currencyKey, true);
            return this.createERC20Contract(tokenAddress);
        }
        return null;
    }
    getSynthContract(currencyKey) {
        const tokenAddress = this.getSynthTokenAddress(currencyKey);
        return this.createERC20Contract(tokenAddress);
    }
    get oneInchApiUrl() {
        return `${process.env.NEXT_PUBLIC_SERVICES_PROXY}/1inch/swap/v5.2/${this.sdk.context.isL2 ? 10 : 1}/`;
    }
    getOneInchQuoteSwapParams(fromTokenAddress, toTokenAddress, amount, decimals) {
        return {
            fromTokenAddress,
            toTokenAddress,
            amount: wei(amount, decimals).toString(0, true),
        };
    }
    getOneInchSwapParams(fromTokenAddress, toTokenAddress, amount, fromTokenDecimals) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = this.getOneInchQuoteSwapParams(fromTokenAddress, toTokenAddress, amount, fromTokenDecimals);
            const res = yield axios.get(this.oneInchApiUrl + 'swap', {
                params: {
                    fromTokenAddress: params.fromTokenAddress,
                    toTokenAddress: params.toTokenAddress,
                    amount: params.amount,
                    fromAddress: this.sdk.context.walletAddress,
                    slippage: DEFAULT_1INCH_SLIPPAGE,
                    PROTOCOLS,
                    referrerAddress: KWENTA_REFERRAL_ADDRESS,
                    disableEstimate: true,
                    includeTokenInfo: true,
                },
            });
            return res.data;
        });
    }
    quoteOneInch(fromTokenAddress, toTokenAddress, amount, decimals) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = this.getOneInchQuoteSwapParams(fromTokenAddress, toTokenAddress, amount, decimals);
            const response = yield axios.get(this.oneInchApiUrl + 'quote', {
                params: {
                    fromTokenAddress: params.fromTokenAddress,
                    toTokenAddress: params.toTokenAddress,
                    amount: params.amount,
                    PROTOCOLS,
                    includeTokensInfo: true,
                },
            });
            return ethers.utils
                .formatUnits(response.data.toAmount, response.data.toToken.decimals)
                .toString();
        });
    }
    swapSynthSwapGasEstimate(fromToken, toToken, fromAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.swapSynthSwapInto(fromToken, toToken, fromAmount);
        });
    }
    getPairRates(fromCurrencyKey, toCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.checkIsAtomic(fromCurrencyKey, toCurrencyKey)
                ? yield Promise.all([
                    this.getAtomicRates(fromCurrencyKey),
                    this.getAtomicRates(toCurrencyKey),
                ])
                : this.getExchangeRatesTupleForCurrencies(this.sdk.prices.currentPrices.onChain, fromCurrencyKey, toCurrencyKey);
        });
    }
    getOneInchApproveAddress() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield axios.get(this.oneInchApiUrl + 'approve/spender');
            return response.data.address;
        });
    }
    getGasEstimateForExchange(txProvider, fromCurrencyKey, toCurrencyKey, quoteAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.isL2)
                return null;
            const fromTokenAddress = this.getTokenAddress(fromCurrencyKey);
            const toTokenAddress = this.getTokenAddress(toCurrencyKey);
            const quoteDecimals = this.getTokenDecimals(fromCurrencyKey);
            if (txProvider === 'synthswap') {
                const [gasEstimate, metaTx] = yield Promise.all([
                    this.swapSynthSwapGasEstimate(this.allTokensMap[fromCurrencyKey], this.allTokensMap[toCurrencyKey], quoteAmount),
                    this.swapSynthSwapInto(this.allTokensMap[fromCurrencyKey], this.allTokensMap[toCurrencyKey], quoteAmount),
                ]);
                // @ts-ignore TODO: Fix types from metaTx
                const l1Fee = yield this.sdk.transactions.getOptimismLayerOneFees(Object.assign(Object.assign({}, metaTx), { gasPrice: 0, gasLimit: Number(gasEstimate) }));
                return { limit: normalizeGasLimit(Number(gasEstimate)), l1Fee };
            }
            else if (txProvider === '1inch') {
                const [estimate, metaTx] = yield Promise.all([
                    this.swapOneInchGasEstimate(fromTokenAddress, toTokenAddress, quoteAmount, quoteDecimals),
                    this.swapOneInchMeta(fromTokenAddress, toTokenAddress, quoteAmount, quoteDecimals),
                ]);
                const l1Fee = yield this.sdk.transactions.getOptimismLayerOneFees(Object.assign(Object.assign({}, metaTx), { gasPrice: 0, gasLimit: Number(estimate) }));
                return { limit: normalizeGasLimit(Number(estimate)), l1Fee };
            }
        });
    }
    isCurrencyETH(currencyKey) {
        return currencyKey === CRYPTO_CURRENCY_MAP.ETH;
    }
    getTokenAddress(currencyKey, coingecko) {
        if (currencyKey != null) {
            if (this.isCurrencyETH(currencyKey)) {
                return coingecko ? ETH_COINGECKO_ADDRESS : ETH_ADDRESS;
            }
            else {
                return get(this.allTokensMap, [currencyKey, 'address'], null);
            }
        }
        else {
            return null;
        }
    }
    getSynthTokenAddress(currencyKey) {
        if (currencyKey != null) {
            const data = synths[currencyKey].addresses[this.sdk.context.networkId];
            return data;
        }
        else {
            return null;
        }
    }
    getCoingeckoPricesForCurrencies(coingeckoPrices, baseAddress) {
        if (!coingeckoPrices || !baseAddress) {
            return wei(0);
        }
        const base = (baseAddress === ETH_ADDRESS ? ETH_COINGECKO_ADDRESS : baseAddress).toLowerCase();
        if (!coingeckoPrices[base]) {
            return wei(0);
        }
        return wei(coingeckoPrices[base].usd);
    }
    getExchangeRatesForCurrencies(rates, base, quote) {
        base = ADDITIONAL_MARKETS.has(base) ? synthToAsset(base) : base;
        return !rates || !base || !quote || !rates[base] || !rates[quote]
            ? wei(0)
            : rates[base].div(rates[quote]);
    }
    getExchangeRatesTupleForCurrencies(rates, base, quote) {
        base = ADDITIONAL_MARKETS.has(base) ? synthToAsset(base) : base;
        const baseRate = !rates || !base || !rates[base] ? wei(0) : rates[base];
        const quoteRate = !rates || !quote || !rates[quote] ? wei(0) : rates[quote];
        return [baseRate, quoteRate];
    }
    createERC20Contract(tokenAddress) {
        return new ethers.Contract(tokenAddress, erc20Abi, this.sdk.context.provider);
    }
}
