import { Chain, connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
	braveWallet,
	coinbaseWallet,
	injectedWallet,
	metaMaskWallet,
	rainbowWallet,
	safeWallet,
	walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { configureChains, createClient } from 'wagmi'
import { bsc } from 'wagmi/chains'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

import BinanceIcon from 'assets/png/rainbowkit/binance.png'
import Frame from 'components/Rainbowkit/Frame'
import Tally from 'components/Rainbowkit/Tally'

const bscWithIcon: Chain = {
	...bsc,
	iconUrl: async () => BinanceIcon,
}

const tenderly = {
	id: 42161,
	name: 'Tenderly Fork',
	iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
	iconBackground: '#fff',
	nativeCurrency: { name: 'Ethereum', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: { http: ['https://rpc.tenderly.co/fork/971f5d32-c058-4d40-afcc-d7dd164ef827'] },
		public: { http: ['https://rpc.tenderly.co/fork/971f5d32-c058-4d40-afcc-d7dd164ef827'] },
	},
	network: 'arbitrum',
	blockExplorers: {
		default: { name: 'SnowTrace', url: 'https://arbiscan.io' },
	},
} as const satisfies Chain

export const chain = {
	// optimism,
	// mainnet,
	// arbitrum,
	// polygon,
	// avalanche,
	// bsc: bscWithIcon,
	// goerli,
	// optimismGoerli,
	tenderly,
}

// DEPLOYMENT: Change all chains thing
const { chains, provider } = configureChains(Object.values(chain), [
	// infuraProvider({
	// 	apiKey: process.env.NEXT_PUBLIC_INFURA_PROJECT_ID!,
	// 	stallTimeout: STALL_TIMEOUT,
	// 	priority: process.env.NEXT_PUBLIC_PROVIDER_ID === 'INFURA' ? 1 : 3,
	// }),
	// jsonRpcProvider({
	// 	rpc: (networkChain) => ({
	// 		http:
	// 			process.env.NEXT_PUBLIC_DEVNET_ENABLED === 'true'
	// 				? process.env.NEXT_PUBLIC_DEVNET_RPC_URL!
	// 				: !BLAST_NETWORK_LOOKUP[networkChain.id]
	// 				? networkChain.rpcUrls.default.http[0]
	// 				: `https://${BLAST_NETWORK_LOOKUP[networkChain.id]}.blastapi.io/${
	// 						process.env.NEXT_PUBLIC_BLASTAPI_PROJECT_ID
	// 				  }`,
	// 	}),
	// 	stallTimeout: STALL_TIMEOUT,
	// 	priority: process.env.NEXT_PUBLIC_DEVNET_ENABLED ? 0 : 2,
	// }),
	jsonRpcProvider({
		rpc: () => ({
			// DEPLOYMENT: Change RPC to arbitrum
			http: 'https://rpc.tenderly.co/fork/971f5d32-c058-4d40-afcc-d7dd164ef827',
			// http: 'https://arb1.arbitrum.io/rpc',
		}),
	}),
	// publicProvider({ stallTimeout: STALL_TIMEOUT, priority: 5 }),
])

const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_V2_ID!

const connectors = connectorsForWallets([
	{
		groupName: 'Popular',
		wallets: [
			safeWallet({ chains }),
			metaMaskWallet({ projectId, chains }),
			rainbowWallet({ projectId, chains }),
			coinbaseWallet({ appName: 'SFCX', chains }),
			walletConnectWallet({ projectId, chains }),
		],
	},
	{
		groupName: 'More',
		wallets: [
			braveWallet({ chains, shimDisconnect: true }),
			Tally({ chains, shimDisconnect: true }),
			Frame({ chains, shimDisconnect: true }),
			injectedWallet({ chains, shimDisconnect: true }),
		],
	},
])

export const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider,
})

export { chains }
