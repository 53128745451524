// export const synths: Record<SynthSymbol, BasicSynth> = {
// 	sAAVE: {
// 		name: 'Aave',
// 		asset: 'AAVE',
// 		addresses: {
// 			1: '0xd2dF355C19471c8bd7D8A3aa27Ff4e26A21b4076',
// 		},
// 		category: 'crypto',
// 	},
// 	sADA: {
// 		name: 'Cardano',
// 		asset: 'ADA',
// 		addresses: {
// 			1: '0xe36E2D3c7c34281FA3bC737950a68571736880A1',
// 		},
// 		category: 'crypto',
// 	},
// 	sAUD: {
// 		name: 'Australian Dollars',
// 		asset: 'AUD',
// 		addresses: {
// 			1: '0xF48e200EAF9906362BB1442fca31e0835773b8B4',
// 		},
// 		category: 'forex',
// 	},
// 	sBTC: {
// 		name: 'Bitcoin',
// 		asset: 'BTC',
// 		addresses: {
// 			1: '0xfE18be6b3Bd88A2D2A7f928d00292E7a9963CfC6',
// 			5: '0xf04fD9A2C265E7828832E9EFb01474b686CacD14',
// 			10: '0x298B9B95708152ff6968aafd889c6586e9169f1D',
// 			420: '0x23c7a77D22Fc1274eCecB703f74699500db106E6',
// 		},
// 		category: 'crypto',
// 	},
// 	sCHF: {
// 		name: 'Swiss Franc',
// 		asset: 'CHF',
// 		addresses: {
// 			1: '0x0F83287FF768D1c1e17a42F44d644D7F22e8ee1d',
// 		},
// 		category: 'forex',
// 	},
// 	sDOT: {
// 		name: 'Polkadot',
// 		asset: 'DOT',
// 		addresses: {
// 			1: '0x1715AC0743102BF5Cd58EfBB6Cf2dC2685d967b6',
// 		},
// 		category: 'crypto',
// 	},
// 	sETH: {
// 		name: 'Ethereum',
// 		asset: 'ETH',
// 		addresses: {
// 			1: '0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb',
// 			5: '0x37216d2366B68Bd8bC64Eb01B83EFA765C21b483',
// 			10: '0xE405de8F52ba7559f9df3C368500B6E6ae6Cee49',
// 			420: '0x6c3856488e664C6b0380AAEfBFD1c28cd6727eC8',
// 		},
// 		category: 'crypto',
// 	},
// 	sETHBTC: {
// 		asset: 'ETHBTC',
// 		name: 'ETH / BTC',
// 		addresses: {
// 			1: '0x104eDF1da359506548BFc7c25bA1E28C16a70235',
// 		},
// 		category: 'crypto',
// 	},
// 	sEUR: {
// 		name: 'Euro',
// 		asset: 'EUR',
// 		addresses: {
// 			1: '0xD71eCFF9342A5Ced620049e616c5035F1dB98620',
// 			10: '0xFBc4198702E81aE77c06D58f81b629BDf36f0a71',
// 		},
// 		category: 'forex',
// 	},
// 	sGBP: {
// 		name: 'Pound Sterling',
// 		asset: 'GBP',
// 		addresses: {
// 			1: '0x97fe22E7341a0Cd8Db6F6C021A24Dc8f4DAD855F',
// 		},
// 		category: 'forex',
// 	},
// 	sINR: {
// 		name: 'Indian Rupees',
// 		asset: 'INR',
// 		addresses: {
// 			10: '0xa3A538EA5D5838dC32dde15946ccD74bDd5652fF',
// 		},
// 		category: 'forex',
// 	},
// 	sJPY: {
// 		name: 'Japanese Yen',
// 		asset: 'JPY',
// 		addresses: {
// 			1: '0xF6b1C627e95BFc3c1b4c9B825a032Ff0fBf3e07d',
// 		},
// 		category: 'forex',
// 	},
// 	sKRW: {
// 		name: 'South Korean Won',
// 		asset: 'KRW',
// 		addresses: {
// 			1: '0x269895a3dF4D73b077Fc823dD6dA1B95f72Aaf9B',
// 		},
// 		category: 'forex',
// 	},
// 	sLINK: {
// 		name: 'Chainlink',
// 		asset: 'LINK',
// 		addresses: {
// 			1: '0xbBC455cb4F1B9e4bFC4B73970d360c8f032EfEE6',
// 		},
// 		category: 'crypto',
// 	},
// 	cfUSD: {
// 		name: 'US Dollars',
// 		asset: 'USD',
// 		addresses: {
// 			1: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
// 			5: '0xB1f664162c0269A469a699709D37cc5739379dD1',
// 			10: '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
// 			420: '0xeBaEAAD9236615542844adC5c149F86C36aD1136',
// 		},
// 		category: 'forex',
// 	},
// }
export const SYNTH_TOKENS = ['cfUSD', 'sETH'];
// DEPLOYMENT: Add token addresses in Arbitrum Chain
export const synths = {
    cfUSD: {
        name: 'cfUSD',
        asset: 'cfUSD',
        addresses: {
            '42161': '0x00BBf85b370dd635D049267721D64aE4449b90A9',
        },
        category: 'commodity',
    },
    cfPlatinum: {
        name: 'Platinum',
        asset: 'cfPlatinum',
        addresses: {
            '42161': '0x9c4aCA34A1Bbd11a3eCEdd49B76d8ba4DF2a48fE',
        },
        category: 'commodity',
    },
    cfLivestock: {
        name: 'Live Cattle Futures',
        asset: 'cfLivestock',
        addresses: {
            '42161': '0x76f5dEd8F7a6a0305A26cE6684cd7752DfCCAA4c',
        },
        category: 'commodity',
    },
    cfSilver: {
        name: 'Silver Futures',
        asset: 'cfSilver',
        addresses: {
            '42161': '0xaf7477C3457F14220849c92E8d52607eae2c7feE',
        },
        category: 'commodity',
    },
    cfCopper: {
        name: 'Copper',
        asset: 'cfCopper',
        addresses: {
            '42161': '0x000CdC37c7957B9342245452789A175eBFd7e03a',
        },
        category: 'commodity',
    },
    cfGold: {
        name: 'Gold Futures',
        asset: 'cfGold',
        addresses: {
            '42161': '0x73E059179cb599C51aFEbEC51d4997291d2eD574',
        },
        category: 'commodity',
    },
    cfCrudeOil: {
        name: 'Crude Oil',
        asset: 'cfCrudeOil',
        addresses: {
            '42161': '0x8c29F8A52921C201172373943f08A42242ec747c',
        },
        category: 'commodity',
    },
    cfCocoa: {
        name: 'Cocoa',
        asset: 'cfCocoa',
        addresses: {
            '42161': '0x6072658DD4Cdf09D1eE8Dd7CF694549D44799beb',
        },
        category: 'commodity',
    },
    cfSoybeans: {
        name: 'Soybean Meal Futures',
        asset: 'cfSoybeans',
        addresses: {
            '42161': '0x9B8204fC0499Cfe7a4C3403b5595608e2768B559',
        },
        category: 'commodity',
    },
    cfNaturalGas: {
        name: 'Natural Gas',
        asset: 'cfNaturalGas',
        addresses: {
            '42161': '0x7D03C0da498E3B97136d0Aa01446977a9fc385E1',
        },
        category: 'commodity',
    },
    cfWheat: {
        name: 'Wheat Futures',
        asset: 'cfWheat',
        addresses: {
            '42161': '0x8E77BCe8EC7Bb7fbB4DF37FAa85A9dc7C49F2620',
        },
        category: 'commodity',
    },
    cfPalladium: {
        name: 'Palladium',
        asset: 'cfPalladium',
        addresses: {
            '42161': '0xA35aE6409b2418f011c9D11551402Ce62Fd6337c',
        },
        category: 'commodity',
    },
    cfSugar: {
        name: 'Sugar',
        asset: 'cfSugar',
        addresses: {
            '42161': '0x1161bBac4139A18610274D902BA74BF039E187ac',
        },
        category: 'commodity',
    },
    cfCoffee: {
        name: 'Coffee',
        asset: 'cfCoffee',
        addresses: {
            '42161': '0xa625310AB85769B045c5fA26122fBe9Baa0D773a',
        },
        category: 'commodity',
    },
    cfCotton: {
        name: 'Cotton',
        asset: 'cfCotton',
        addresses: {
            '42161': '0x1c87464b20A19a582B1D6c02a9a2839bF63934D3',
        },
        category: 'commodity',
    },
    cfOrangeJuice: {
        name: 'Orange Juice',
        asset: 'cfOrangeJuice',
        addresses: {
            '42161': '0x2C5f05f408372FfFa57B9153229CDc996437962B',
        },
        category: 'commodity',
    },
};
const synthsByNetwork = (id) => Object.entries(synths).reduce((acc, [symbol, config]) => {
    const address = config.addresses[id];
    if (address) {
        acc[symbol] = {
            name: symbol,
            description: config.name,
            asset: config.asset,
            address,
            decimals: 18,
            category: config.category,
        };
    }
    return acc;
}, {});
// DEPLOYMENT: Change/Add network ID
const SYNTHS_BY_NETWORK = {
    1: {
        map: synthsByNetwork(1),
        list: Object.values(synthsByNetwork(1)),
    },
    10: {
        map: synthsByNetwork(10),
        list: Object.values(synthsByNetwork(10)),
    },
    5: {
        map: synthsByNetwork(5),
        list: Object.values(synthsByNetwork(5)),
    },
    420: {
        map: synthsByNetwork(420),
        list: Object.values(synthsByNetwork(420)),
    },
    42161: {
        map: synthsByNetwork(42161),
        list: Object.values(synthsByNetwork(42161)),
    },
};
export const getSynthsForNetwork = (networkId) => {
    var _a, _b;
    return (_b = (_a = SYNTHS_BY_NETWORK[networkId]) === null || _a === void 0 ? void 0 : _a.map) !== null && _b !== void 0 ? _b : {};
};
export const getSynthsListForNetwork = (networkId) => {
    var _a, _b;
    return (_b = (_a = SYNTHS_BY_NETWORK[networkId]) === null || _a === void 0 ? void 0 : _a.list) !== null && _b !== void 0 ? _b : [];
};
