var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import request, { gql } from 'graphql-request';
export const queryOperatorsByOwner = (sdk, walletAddress) => __awaiter(void 0, void 0, void 0, function* () {
    if (!walletAddress)
        return [];
    const response = yield request(sdk.kwentaToken.stakingGqlEndpoint, gql `
			query operatorApproveds($walletAddress: String!) {
				operatorApproveds(
					orderBy: blockTimestamp
					orderDirection: desc
					where: { owner: $walletAddress }
				) {
					operator
					blockTimestamp
					approved
				}
			}
		`, { walletAddress });
    return (response === null || response === void 0 ? void 0 : response.operatorApproveds) || [];
});
