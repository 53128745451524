/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from 'ethers';
const _abi = [
    {
        inputs: [
            {
                internalType: 'address',
                name: '_owner',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_resolver',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'aggregator',
                type: 'address',
            },
        ],
        name: 'AggregatorAdded',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'aggregator',
                type: 'address',
            },
        ],
        name: 'AggregatorRemoved',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'bytes32',
                name: 'name',
                type: 'bytes32',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'destination',
                type: 'address',
            },
        ],
        name: 'CacheUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'oldOwner',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnerChanged',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnerNominated',
        type: 'event',
    },
    {
        constant: true,
        inputs: [],
        name: 'CONTRACT_NAME',
        outputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'acceptOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'address',
                name: 'aggregatorAddress',
                type: 'address',
            },
        ],
        name: 'addAggregator',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        name: 'aggregatorKeys',
        outputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'aggregatorWarningFlags',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        name: 'aggregators',
        outputs: [
            {
                internalType: 'contract AggregatorV2V3Interface',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32[]',
                name: 'currencyKeys',
                type: 'bytes32[]',
            },
        ],
        name: 'anyRateIsInvalid',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32[]',
                name: 'currencyKeys',
                type: 'bytes32[]',
            },
            {
                internalType: 'uint256[]',
                name: 'roundIds',
                type: 'uint256[]',
            },
        ],
        name: 'anyRateIsInvalidAtRound',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'aggregator',
                type: 'address',
            },
        ],
        name: 'currenciesUsingAggregator',
        outputs: [
            {
                internalType: 'bytes32[]',
                name: 'currencies',
                type: 'bytes32[]',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        name: 'currencyKeyDecimals',
        outputs: [
            {
                internalType: 'uint8',
                name: '',
                type: 'uint8',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        name: 'effectiveAtomicValueAndRates',
        outputs: [
            {
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'systemValue',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'systemSourceRate',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'systemDestinationRate',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'sourceCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'sourceAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'destinationCurrencyKey',
                type: 'bytes32',
            },
        ],
        name: 'effectiveValue',
        outputs: [
            {
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'sourceCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'sourceAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'destinationCurrencyKey',
                type: 'bytes32',
            },
        ],
        name: 'effectiveValueAndRates',
        outputs: [
            {
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'sourceRate',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'destinationRate',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'sourceCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'sourceAmount',
                type: 'uint256',
            },
            {
                internalType: 'bytes32',
                name: 'destinationCurrencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'roundIdForSrc',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'roundIdForDest',
                type: 'uint256',
            },
        ],
        name: 'effectiveValueAndRatesAtRound',
        outputs: [
            {
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'sourceRate',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'destinationRate',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'getCurrentRoundId',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'startingRoundId',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'startingTimestamp',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'timediff',
                type: 'uint256',
            },
        ],
        name: 'getLastRoundIdBeforeElapsedSecs',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'isResolverCached',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'lastRateUpdateTimes',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32[]',
                name: 'currencyKeys',
                type: 'bytes32[]',
            },
        ],
        name: 'lastRateUpdateTimesForCurrencies',
        outputs: [
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: '_owner',
                type: 'address',
            },
        ],
        name: 'nominateNewOwner',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'nominatedOwner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'rateAndInvalid',
        outputs: [
            {
                internalType: 'uint256',
                name: 'rate',
                type: 'uint256',
            },
            {
                internalType: 'bool',
                name: 'isInvalid',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'roundId',
                type: 'uint256',
            },
        ],
        name: 'rateAndTimestampAtRound',
        outputs: [
            {
                internalType: 'uint256',
                name: 'rate',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'time',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'rateAndUpdatedTime',
        outputs: [
            {
                internalType: 'uint256',
                name: 'rate',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'time',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'rateForCurrency',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'rateIsFlagged',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'rateIsInvalid',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'rateIsStale',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'rateStalePeriod',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'rateWithSafetyChecks',
        outputs: [
            {
                internalType: 'uint256',
                name: 'rate',
                type: 'uint256',
            },
            {
                internalType: 'bool',
                name: 'broken',
                type: 'bool',
            },
            {
                internalType: 'bool',
                name: 'staleOrInvalid',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32[]',
                name: 'currencyKeys',
                type: 'bytes32[]',
            },
        ],
        name: 'ratesAndInvalidForCurrencies',
        outputs: [
            {
                internalType: 'uint256[]',
                name: 'rates',
                type: 'uint256[]',
            },
            {
                internalType: 'bool',
                name: 'anyRateInvalid',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
            {
                internalType: 'uint256',
                name: 'numRounds',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'roundId',
                type: 'uint256',
            },
        ],
        name: 'ratesAndUpdatedTimeForCurrencyLastNRounds',
        outputs: [
            {
                internalType: 'uint256[]',
                name: 'rates',
                type: 'uint256[]',
            },
            {
                internalType: 'uint256[]',
                name: 'times',
                type: 'uint256[]',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32[]',
                name: 'currencyKeys',
                type: 'bytes32[]',
            },
        ],
        name: 'ratesForCurrencies',
        outputs: [
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'rebuildCache',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                internalType: 'bytes32',
                name: 'currencyKey',
                type: 'bytes32',
            },
        ],
        name: 'removeAggregator',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'resolver',
        outputs: [
            {
                internalType: 'contract AddressResolver',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'resolverAddressesRequired',
        outputs: [
            {
                internalType: 'bytes32[]',
                name: 'addresses',
                type: 'bytes32[]',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'bytes32',
                name: '',
                type: 'bytes32',
            },
        ],
        name: 'synthTooVolatileForAtomicExchange',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                components: [
                    {
                        internalType: 'bytes32',
                        name: 'currencyKey',
                        type: 'bytes32',
                    },
                    {
                        internalType: 'address',
                        name: 'dexPriceAggregator',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'atomicEquivalentForDexPricing',
                        type: 'address',
                    },
                    {
                        internalType: 'uint256',
                        name: 'atomicExchangeFeeRate',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'atomicTwapWindow',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'atomicMaxVolumePerBlock',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'atomicVolatilityConsiderationWindow',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'atomicVolatilityUpdateThreshold',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'exchangeFeeRate',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'exchangeMaxDynamicFee',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'exchangeDynamicFeeRounds',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'exchangeDynamicFeeThreshold',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'exchangeDynamicFeeWeightDecay',
                        type: 'uint256',
                    },
                ],
                internalType: 'struct IDirectIntegrationManager.ParameterIntegrationSettings',
                name: '',
                type: 'tuple',
            },
        ],
        name: 'synthTooVolatileForAtomicExchange',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
];
export class ExchangeRates__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
ExchangeRates__factory.abi = _abi;
