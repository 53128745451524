/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from 'ethers';
const _abi = [
    {
        inputs: [
            { internalType: 'address payable', name: '_proxy', type: 'address' },
            { internalType: 'address', name: '_tokenState', type: 'address' },
            { internalType: 'address', name: '_owner', type: 'address' },
            { internalType: 'uint256', name: '_totalSupply', type: 'uint256' },
            { internalType: 'address', name: '_resolver', type: 'address' },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    { inputs: [], name: 'AccessControlBadConfirmation', type: 'error' },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'bytes32', name: 'neededRole', type: 'bytes32' },
        ],
        name: 'AccessControlUnauthorizedAccount',
        type: 'error',
    },
    {
        inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
        name: 'OwnableInvalidOwner',
        type: 'error',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'OwnableUnauthorizedAccount',
        type: 'error',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'account', type: 'address' },
            { indexed: false, internalType: 'uint256', name: 'scfxRedeemed', type: 'uint256' },
            { indexed: false, internalType: 'uint256', name: 'amountLiquidated', type: 'uint256' },
            { indexed: false, internalType: 'address', name: 'liquidator', type: 'address' },
        ],
        name: 'AccountLiquidated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
            { indexed: true, internalType: 'address', name: 'spender', type: 'address' },
            { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'Approval',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'account', type: 'address' },
            { indexed: false, internalType: 'bytes32', name: 'fromCurrencyKey', type: 'bytes32' },
            { indexed: false, internalType: 'uint256', name: 'fromAmount', type: 'uint256' },
            { indexed: false, internalType: 'bytes32', name: 'toCurrencyKey', type: 'bytes32' },
            { indexed: false, internalType: 'uint256', name: 'toAmount', type: 'uint256' },
            { indexed: false, internalType: 'address', name: 'toAddress', type: 'address' },
        ],
        name: 'AtomicSynthExchange',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'account', type: 'address' },
            { indexed: false, internalType: 'bool', name: 'isBlacklisted', type: 'bool' },
        ],
        name: 'BlacklistUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'bytes32', name: 'name', type: 'bytes32' },
            { indexed: false, internalType: 'address', name: 'destination', type: 'address' },
        ],
        name: 'CacheUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'account', type: 'address' },
            { indexed: false, internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' },
            { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'ExchangeRebate',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'account', type: 'address' },
            { indexed: false, internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' },
            { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'ExchangeReclaim',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
            { indexed: false, internalType: 'bytes32', name: 'toCurrencyKey', type: 'bytes32' },
            { indexed: false, internalType: 'uint256', name: 'toAmount', type: 'uint256' },
            { indexed: false, internalType: 'uint256', name: 'fee', type: 'uint256' },
        ],
        name: 'ExchangeTracking',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
            { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'address', name: 'proxyAddress', type: 'address' }],
        name: 'ProxyUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32' },
            { indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32' },
        ],
        name: 'RoleAdminChanged',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { indexed: true, internalType: 'address', name: 'account', type: 'address' },
            { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
        ],
        name: 'RoleGranted',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { indexed: true, internalType: 'address', name: 'account', type: 'address' },
            { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
        ],
        name: 'RoleRevoked',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: 'address', name: 'poolAddress', type: 'address' },
            { indexed: false, internalType: 'bool', name: 'val', type: 'bool' },
        ],
        name: 'SetPool',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'account', type: 'address' },
            { indexed: false, internalType: 'bytes32', name: 'fromCurrencyKey', type: 'bytes32' },
            { indexed: false, internalType: 'uint256', name: 'fromAmount', type: 'uint256' },
            { indexed: false, internalType: 'bytes32', name: 'toCurrencyKey', type: 'bytes32' },
            { indexed: false, internalType: 'uint256', name: 'toAmount', type: 'uint256' },
            { indexed: false, internalType: 'address', name: 'toAddress', type: 'address' },
        ],
        name: 'SynthExchange',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'address', name: 'newTokenState', type: 'address' }],
        name: 'TokenStateUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'from', type: 'address' },
            { indexed: true, internalType: 'address', name: 'to', type: 'address' },
            { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'Transfer',
        type: 'event',
    },
    {
        inputs: [],
        name: 'BURNER_ROLE',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'CONTRACT_NAME',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'DECIMALS',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'DEFAULT_ADMIN_ROLE',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'MINTER_ROLE',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'TOKEN_NAME',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'TOKEN_SYMBOL',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'activeTrade',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'address', name: 'spender', type: 'address' },
        ],
        name: 'allowance',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'anySynthOrSCFXRateIsInvalid',
        outputs: [{ internalType: 'bool', name: 'anyRateInvalid', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'availableCurrencyKeys',
        outputs: [{ internalType: 'bytes32[]', name: '', type: 'bytes32[]' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'availableSynthCount',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
        name: 'availableSynths',
        outputs: [{ internalType: 'contract ISynth', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'blacklist',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    { inputs: [], name: 'burn', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    {
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'burnSynths',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'burnForAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'burnSynthsOnBehalf',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'burnSynthsToTarget',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'burnForAddress', type: 'address' }],
        name: 'burnSynthsToTargetOnBehalf',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'collateral',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_issuer', type: 'address' }],
        name: 'collateralisationRatio',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'createMaxSynths',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'createSynths',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' },
        ],
        name: 'debtBalanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'decimals',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'deploymentSet',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'bytes32', name: 'fromCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'fromAmount', type: 'uint256' },
            { internalType: 'bytes32', name: 'toCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'toAmount', type: 'uint256' },
            { internalType: 'address', name: 'toAddress', type: 'address' },
        ],
        name: 'emitAtomicSynthExchange',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'emitExchangeRebate',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'emitExchangeReclaim',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
            { internalType: 'bytes32', name: 'toCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'toAmount', type: 'uint256' },
            { internalType: 'uint256', name: 'fee', type: 'uint256' },
        ],
        name: 'emitExchangeTracking',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'bytes32', name: 'fromCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'fromAmount', type: 'uint256' },
            { internalType: 'bytes32', name: 'toCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'toAmount', type: 'uint256' },
            { internalType: 'address', name: 'toAddress', type: 'address' },
        ],
        name: 'emitSynthExchange',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'sourceCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'sourceAmount', type: 'uint256' },
            { internalType: 'bytes32', name: 'destinationCurrencyKey', type: 'bytes32' },
            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
            { internalType: 'uint256', name: 'minAmount', type: 'uint256' },
        ],
        name: 'exchangeAtomically',
        outputs: [{ internalType: 'uint256', name: 'amountReceived', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'exchangeForAddress', type: 'address' },
            { internalType: 'bytes32', name: 'sourceCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'sourceAmount', type: 'uint256' },
            { internalType: 'bytes32', name: 'destinationCurrencyKey', type: 'bytes32' },
        ],
        name: 'exchangeOnBehalf',
        outputs: [{ internalType: 'uint256', name: 'amountReceived', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'exchangeForAddress', type: 'address' },
            { internalType: 'bytes32', name: 'sourceCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'sourceAmount', type: 'uint256' },
            { internalType: 'bytes32', name: 'destinationCurrencyKey', type: 'bytes32' },
            { internalType: 'address', name: 'rewardAddress', type: 'address' },
            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
        ],
        name: 'exchangeOnBehalfWithTracking',
        outputs: [{ internalType: 'uint256', name: 'amountReceived', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'sourceCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'sourceAmount', type: 'uint256' },
            { internalType: 'bytes32', name: 'destinationCurrencyKey', type: 'bytes32' },
            { internalType: 'address', name: 'rewardAddress', type: 'address' },
            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
        ],
        name: 'exchangeWithTracking',
        outputs: [{ internalType: 'uint256', name: 'amountReceived', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'sourceCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'sourceAmount', type: 'uint256' },
            { internalType: 'bytes32', name: 'destinationCurrencyKey', type: 'bytes32' },
            { internalType: 'address', name: 'rewardAddress', type: 'address' },
            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
        ],
        name: 'exchangeWithTrackingForInitiator',
        outputs: [{ internalType: 'uint256', name: 'amountReceived', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'sourceCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'sourceAmount', type: 'uint256' },
            { internalType: 'bytes32', name: 'destinationCurrencyKey', type: 'bytes32' },
            { internalType: 'bytes32', name: 'trackingCode', type: 'bytes32' },
        ],
        name: 'exchangeWithVirtual',
        outputs: [
            { internalType: 'uint256', name: 'amountReceived', type: 'uint256' },
            { internalType: 'contract IVirtualSynth', name: 'vSynth', type: 'address' },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'sourceCurrencyKey', type: 'bytes32' },
            { internalType: 'uint256', name: 'sourceAmount', type: 'uint256' },
            { internalType: 'bytes32', name: 'destinationCurrencyKey', type: 'bytes32' },
        ],
        name: 'executeExchange',
        outputs: [{ internalType: 'uint256', name: 'amountReceived', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'getFirstNonZeroEscrowIndex',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
        name: 'getRoleAdmin',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { internalType: 'address', name: 'account', type: 'address' },
        ],
        name: 'grantRole',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { internalType: 'address', name: 'account', type: 'address' },
        ],
        name: 'hasRole',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'isResolverCached',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' }],
        name: 'isWaitingPeriod',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'issueForAddress', type: 'address' }],
        name: 'issueMaxSynthsOnBehalf',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'issueForAddress', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'issueSynthsOnBehalf',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'liquidateDelinquentAccount',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'uint256', name: 'escrowStartIndex', type: 'uint256' },
        ],
        name: 'liquidateDelinquentAccountEscrowIndex',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'liquidateSelf',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'maxIssuableSynths',
        outputs: [{ internalType: 'uint256', name: 'maxIssuable', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'messageSender',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'migrateAccountBalances',
        outputs: [
            { internalType: 'uint256', name: 'totalEscrowRevoked', type: 'uint256' },
            { internalType: 'uint256', name: 'totalLiquidBalance', type: 'uint256' },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'migrateEscrowBalanceToRewardEscrowV2',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'migrateEscrowContractBalance',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'mint',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'pool',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'proxy',
        outputs: [{ internalType: 'contract Proxy', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'refreshCache',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'remainingIssuableSynths',
        outputs: [
            { internalType: 'uint256', name: 'maxIssuable', type: 'uint256' },
            { internalType: 'uint256', name: 'alreadyIssued', type: 'uint256' },
            { internalType: 'uint256', name: 'totalSystemDebt', type: 'uint256' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { internalType: 'address', name: 'callerConfirmation', type: 'address' },
        ],
        name: 'renounceRole',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'reserveAddr',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'resolver',
        outputs: [{ internalType: 'contract AddressResolver', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'resolverAddressesRequired',
        outputs: [{ internalType: 'bytes32[]', name: 'addresses', type: 'bytes32[]' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'revokeAllEscrow',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'bytes32', name: 'role', type: 'bytes32' },
            { internalType: 'address', name: 'account', type: 'address' },
        ],
        name: 'revokeRole',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'cfUSD',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }],
        name: 'setBurnAmount',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
        name: 'setMessageSender',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'poolAddr', type: 'address' },
            { internalType: 'bool', name: 'val', type: 'bool' },
        ],
        name: 'setPool',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address payable', name: '_proxy', type: 'address' }],
        name: 'setProxy',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '_reserveAddr', type: 'address' }],
        name: 'setReserveAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'contract TokenState', name: '_tokenState', type: 'address' }],
        name: 'setTokenState',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bool', name: 'val', type: 'bool' }],
        name: 'setTrade',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' }],
        name: 'settle',
        outputs: [
            { internalType: 'uint256', name: 'reclaimed', type: 'uint256' },
            { internalType: 'uint256', name: 'refunded', type: 'uint256' },
            { internalType: 'uint256', name: 'numEntriesSettled', type: 'uint256' },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
        name: 'supportsInterface',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' }],
        name: 'synths',
        outputs: [{ internalType: 'contract ISynth', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'synthAddress', type: 'address' }],
        name: 'synthsByAddress',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'tokenState',
        outputs: [{ internalType: 'contract TokenState', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' }],
        name: 'totalIssuedSynths',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' }],
        name: 'totalIssuedSynthsExcludeOtherCollateral',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'transferFrom',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'transferableSynDex',
        outputs: [{ internalType: 'uint256', name: 'transferable', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_account', type: 'address' },
            { internalType: 'bool', name: '_isBlacklisted', type: 'bool' },
        ],
        name: 'updateBlacklist',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
export class Synthetix__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
Synthetix__factory.abi = _abi;
