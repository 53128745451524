var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import request, { gql } from 'graphql-request';
import { REFERRALS_ENDPOINTS } from '../constants/referrals';
import { queryCodesByReferrer, queryTradersByCode } from '../queries/referrals';
import { ZERO_WEI } from '../constants';
const calculateTraderVolume = (futuresTrades) => {
    return futuresTrades.reduce((acc, trade) => {
        return acc + (Math.abs(+trade.size) / 1e18) * (+trade.price / 1e18);
    }, 0);
};
export const getReferralsGqlEndpoint = (networkId) => {
    return REFERRALS_ENDPOINTS[networkId] || REFERRALS_ENDPOINTS[10];
};
const getCumulativeStatsByCode = (sdk, account, epochStart, epochEnd, epochPeriod) => __awaiter(void 0, void 0, void 0, function* () {
    const codes = yield queryCodesByReferrer(sdk, account);
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    return Promise.all(codes.map((code) => __awaiter(void 0, void 0, void 0, function* () {
        const traders = yield queryTradersByCode(sdk, code, epochStart, epochEnd);
        const totalRewards = epochPeriod
            ? yield sdk.kwentaToken.getKwentaRewardsByTraders(epochPeriod, traders.map(({ id }) => id))
            : ZERO_WEI;
        const traderVolumeQueries = yield Promise.all(traders.map(({ id, lastMintedAt }) => {
            const start = epochStart ? Math.max(Number(lastMintedAt), epochStart) : lastMintedAt;
            const end = epochEnd ? Math.min(currentTimeInSeconds, epochEnd) : currentTimeInSeconds;
            return gql `
					user_${id}: futuresTrades(
						first: 1000
						where: {
							timestamp_gt: ${start}
							timestamp_lte: ${end}
							trackingCode: "0x4b57454e54410000000000000000000000000000000000000000000000000000"
							account: "${id}"
						}
						orderBy: timestamp
						orderDirection: asc
					) {
						timestamp
						account
						size
						price
					}
					`;
        }));
        if (traderVolumeQueries.length > 0) {
            const response = yield request(sdk.futures.futuresGqlEndpoint, gql `
						query totalFuturesTrades {
							${traderVolumeQueries.join('')}
						}
					`);
            const totalTrades = response ? Object.values(response).flat() : [];
            const totalVolume = calculateTraderVolume(totalTrades);
            return {
                code,
                referredCount: traders.length.toString(),
                referralVolume: totalVolume.toString(),
                earnedRewards: totalRewards.toString(),
                tradesCount: totalTrades.length.toString(),
            };
        }
        else {
            return {
                code,
                referredCount: '0',
                referralVolume: '0',
                earnedRewards: totalRewards.toString(),
                tradesCount: '0',
            };
        }
    })));
});
export const getReferralStatisticsByAccount = (sdk, account) => {
    return getCumulativeStatsByCode(sdk, account);
};
export const getReferralStatisticsByAccountAndEpochTime = (sdk, account, epochStart, epochEnd) => {
    return getCumulativeStatsByCode(sdk, account, epochStart, epochEnd);
};
export const getReferralStatisticsByAccountAndEpoch = (sdk, account, epochPeriod) => {
    return getCumulativeStatsByCode(sdk, account, undefined, undefined, epochPeriod);
};
