var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigNumber } from '@ethersproject/bignumber';
import { wei } from '@synthetixio/wei';
import { ethers } from 'ethers';
import moment from 'moment';
import * as sdkErrors from '../common/errors';
import { ADDRESSES } from '../constants';
import { ETH_COINGECKO_ADDRESS, KWENTA_ADDRESS, OP_ADDRESS } from '../constants/exchange';
import { AGGREGATE_ASSET_KEY, KWENTA_TRACKING_CODE } from '../constants/futures';
import { ZERO_WEI } from '../constants/number';
import { SECONDS_PER_DAY } from '../constants/period';
import { DEFAULT_NUMBER_OF_FUTURES_FEE, OP_REWARDS_CUTOFF_EPOCH, REFERRAL_PROGRAM_START_EPOCH, STAKING_V2_REWARDS_CUTOFF_EPOCH, TRADING_REWARDS_CUTOFF_EPOCH, } from '../constants/staking';
import { queryOperatorsByOwner } from '../queries/staking';
import { calculateFeesForAccount, calculateTotalFees, getStakingGqlEndpoint } from '../utils';
import { formatTruncatedDuration } from '../utils/date';
import { awsClient } from '../utils/files';
import { weiFromWei } from '../utils/number';
import { getFuturesAggregateStats, getFuturesTrades } from '../utils/subgraph';
export default class KwentaTokenService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    get stakingGqlEndpoint() {
        const { networkId } = this.sdk.context;
        return getStakingGqlEndpoint(networkId);
    }
    changePoolTokens(amount, action) {
        if (!this.sdk.context.contracts.StakingRewards) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(this.sdk.context.contracts.StakingRewards, action, [wei(amount).toBN()]);
    }
    approveLPToken() {
        return this.approveToken('KwentaArrakisVault', 'StakingRewards');
    }
    getEarnDetails() {
        return __awaiter(this, void 0, void 0, function* () {
            const { StakingRewards, KwentaArrakisVault } = this.sdk.context.multicallContracts;
            if (!StakingRewards || !KwentaArrakisVault) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            const [balance, earned, periodFinish, rewardRate, totalSupply, lpTokenBalance, allowance, [wethAmount, kwentaAmount], lpTotalSupply,] = yield this.sdk.context.multicallProvider.all([
                StakingRewards.balanceOf(walletAddress),
                StakingRewards.earned(walletAddress),
                StakingRewards.periodFinish(),
                StakingRewards.rewardRate(),
                StakingRewards.totalSupply(),
                KwentaArrakisVault.balanceOf(walletAddress),
                KwentaArrakisVault.allowance(walletAddress, StakingRewards.address),
                KwentaArrakisVault.getUnderlyingBalances(),
                KwentaArrakisVault.totalSupply(),
            ]);
            return {
                balance: wei(balance),
                earned: wei(earned),
                endDate: periodFinish.toNumber(),
                rewardRate: wei(rewardRate),
                totalSupply: wei(totalSupply),
                lpTokenBalance: wei(lpTokenBalance),
                allowance: wei(allowance),
                wethAmount: wei(wethAmount),
                kwentaAmount: wei(kwentaAmount),
                lpTotalSupply: wei(lpTotalSupply),
            };
        });
    }
    getEarnTokenPrices() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const coinGeckoPrices = yield this.sdk.exchange.batchGetCoingeckoPrices([KWENTA_ADDRESS, ETH_COINGECKO_ADDRESS, OP_ADDRESS], false);
            return {
                kwentaPrice: coinGeckoPrices ? wei((_a = coinGeckoPrices[KWENTA_ADDRESS]) === null || _a === void 0 ? void 0 : _a.usd) : ZERO_WEI,
                wethPrice: coinGeckoPrices ? wei((_b = coinGeckoPrices[ETH_COINGECKO_ADDRESS]) === null || _b === void 0 ? void 0 : _b.usd) : ZERO_WEI,
                opPrice: coinGeckoPrices ? wei((_c = coinGeckoPrices[OP_ADDRESS]) === null || _c === void 0 ? void 0 : _c.usd) : ZERO_WEI,
            };
        });
    }
    claimRewards() {
        const StakingRewards = this.sdk.context.contracts.StakingRewards;
        if (!StakingRewards) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(StakingRewards, 'getReward', []);
    }
    getStakingData() {
        return __awaiter(this, void 0, void 0, function* () {
            // const { vKwentaRedeemer, veKwentaRedeemer } = this.sdk.context.contracts
            const { 
            // RewardEscrow,
            KwentaStakingRewards, KwentaToken, SupplySchedule, 
            // vKwentaToken,
            // veKwentaToken,
            MultipleMerkleDistributor, } = this.sdk.context.multicallContracts;
            const { walletAddress, networkId } = this.sdk.context;
            if (
            // !RewardEscrow ||
            !KwentaStakingRewards ||
                !KwentaToken ||
                !SupplySchedule ||
                // !vKwentaToken ||
                !MultipleMerkleDistributor
            // !veKwentaToken ||
            // !vKwentaRedeemer ||
            // !veKwentaRedeemer
            ) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const [totalStakedBalance, apr, stakedNonEscrowedBalance, claimableBalance, smxBalance, 
            // weekCounter,
            kwentaAllowance,] = yield this.sdk.context.multicallProvider.all([
                KwentaStakingRewards.totalStaked(),
                KwentaStakingRewards.APR(),
                KwentaStakingRewards.getStakeDetails(walletAddress),
                KwentaStakingRewards.calculateRewards(walletAddress),
                KwentaToken.balanceOf(walletAddress),
                // SupplySchedule.weekCounter(),
                KwentaToken.allowance(walletAddress, ADDRESSES.KwentaStakingRewards[networkId]),
            ]);
            return {
                // rewardEscrowBalance: wei(rewardEscrowBalance),
                stakedNonEscrowedBalance: wei(stakedNonEscrowedBalance.balance),
                apr: BigNumber.from(apr).toNumber(),
                // stakedEscrowedBalance: wei(stakedEscrowedBalance),
                claimableBalance: wei(claimableBalance),
                smxBalance: wei(smxBalance),
                // weekCounter: Number(weekCounter),
                totalStakedBalance: wei(totalStakedBalance),
                kwentaAllowance: wei(kwentaAllowance),
                // epochPeriod: Math.floor((Math.floor(Date.now() / 1000) - EPOCH_START[10]) / WEEK),
                // veKwentaBalance: wei(veKwentaBalance),
                // veKwentaAllowance: wei(veKwentaAllowance),
            };
        });
    }
    getStakingV2Data() {
        return __awaiter(this, void 0, void 0, function* () {
            const { RewardEscrowV2, KwentaStakingRewardsV2, KwentaToken, SupplySchedule } = this.sdk.context.multicallContracts;
            if (!RewardEscrowV2 || !KwentaStakingRewardsV2 || !KwentaToken || !SupplySchedule) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress, networkId } = this.sdk.context;
            const [rewardEscrowBalance, stakedNonEscrowedBalance, stakedEscrowedBalance, claimableBalance, totalStakedBalance, lastStakedTime, cooldownPeriod, kwentaStakingV2Allowance,] = yield this.sdk.context.multicallProvider.all([
                RewardEscrowV2.escrowedBalanceOf(walletAddress),
                KwentaStakingRewardsV2.nonEscrowedBalanceOf(walletAddress),
                KwentaStakingRewardsV2.escrowedBalanceOf(walletAddress),
                KwentaStakingRewardsV2.earned(walletAddress),
                KwentaStakingRewardsV2.totalSupply(),
                KwentaStakingRewardsV2.userLastStakeTime(walletAddress),
                KwentaStakingRewardsV2.cooldownPeriod(),
                KwentaToken.allowance(walletAddress, ADDRESSES.KwentaStakingRewardsV2[networkId]),
            ]);
            return {
                rewardEscrowBalance: wei(rewardEscrowBalance),
                stakedNonEscrowedBalance: wei(stakedNonEscrowedBalance),
                stakedEscrowedBalance: wei(stakedEscrowedBalance),
                claimableBalance: wei(claimableBalance),
                totalStakedBalance: wei(totalStakedBalance),
                stakedResetTime: Number(lastStakedTime) + Number(cooldownPeriod),
                kwentaStakingV2Allowance: wei(kwentaStakingV2Allowance),
            };
        });
    }
    getEscrowData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { RewardEscrow } = this.sdk.context.contracts;
            const { RewardEscrow: RewardEscrowMulticall } = this.sdk.context.multicallContracts;
            if (!RewardEscrow || !RewardEscrowMulticall) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            const schedules = yield RewardEscrow.getVestingSchedules(walletAddress, 0, DEFAULT_NUMBER_OF_FUTURES_FEE);
            const vestingSchedules = schedules.filter((schedule) => schedule.escrowAmount.gt(0));
            const calls = vestingSchedules.map((schedule) => RewardEscrowMulticall.getVestingEntryClaimable(walletAddress, schedule.entryID));
            const vestingEntries = yield this.sdk.context.multicallProvider.all(calls);
            const { escrowData, totalVestable } = vestingSchedules.reduce((acc, next, i) => {
                const vestable = wei(vestingEntries[i].quantity);
                const date = Number(next.endTime) * 1000;
                acc.totalVestable = acc.totalVestable.add(vestable);
                acc.escrowData.push({
                    id: Number(next.entryID),
                    date: moment(date).format('MM/DD/YY'),
                    time: formatTruncatedDuration(Number(next.endTime) - new Date().getTime() / 1000),
                    vestable,
                    amount: wei(next.escrowAmount),
                    fee: wei(vestingEntries[i].fee),
                    status: date > Date.now() ? 'Vesting' : 'Vested',
                    version: 1,
                });
                return acc;
            }, { escrowData: [], totalVestable: wei(0) });
            return { escrowData, totalVestable };
        });
    }
    getEscrowV2Data() {
        return __awaiter(this, void 0, void 0, function* () {
            const { RewardEscrowV2 } = this.sdk.context.contracts;
            const { RewardEscrowV2: RewardEscrowMulticall } = this.sdk.context.multicallContracts;
            if (!RewardEscrowV2 || !RewardEscrowMulticall) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            const schedules = yield RewardEscrowV2.getVestingSchedules(walletAddress, 0, DEFAULT_NUMBER_OF_FUTURES_FEE);
            const vestingSchedules = schedules.filter((schedule) => schedule.escrowAmount.gt(0));
            const calls = vestingSchedules.map((schedule) => RewardEscrowMulticall.getVestingEntryClaimable(schedule.entryID));
            const vestingEntries = yield this.sdk.context.multicallProvider.all(calls);
            const { escrowData, totalVestable } = vestingSchedules.reduce((acc, next, i) => {
                const vestable = wei(vestingEntries[i].quantity);
                const date = Number(next.endTime) * 1000;
                acc.totalVestable = acc.totalVestable.add(vestable);
                acc.escrowData.push({
                    id: Number(next.entryID),
                    date: moment(date).format('MM/DD/YY'),
                    time: formatTruncatedDuration(Number(next.endTime) - new Date().getTime() / 1000),
                    vestable,
                    amount: wei(next.escrowAmount),
                    fee: wei(vestingEntries[i].fee),
                    status: date > Date.now() ? 'Vesting' : 'Vested',
                    version: 2,
                });
                return acc;
            }, { escrowData: [], totalVestable: wei(0) });
            return { escrowData, totalVestable };
        });
    }
    claimStakingRewards() {
        return __awaiter(this, void 0, void 0, function* () {
            const { KwentaStakingRewards } = this.sdk.context.contracts;
            if (!KwentaStakingRewards) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return this.sdk.transactions.createContractTxn(KwentaStakingRewards, 'claimReward', []);
        });
    }
    claimStakingRewardsV2() {
        const { KwentaStakingRewardsV2 } = this.sdk.context.contracts;
        if (!KwentaStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(KwentaStakingRewardsV2, 'getReward', []);
    }
    compoundRewards() {
        const { KwentaStakingRewardsV2 } = this.sdk.context.contracts;
        if (!KwentaStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(KwentaStakingRewardsV2, 'compound', []);
    }
    // TODO: Replace this with separate functions that use `approveToken`
    // In that case, we can safely remove the map object from this method.
    approveKwentaToken(token, amount = ethers.constants.MaxUint256) {
        const { KwentaToken, KwentaStakingRewards,
        // vKwentaToken,
        // vKwentaRedeemer,
         } = this.sdk.context.contracts;
        const map = {
            kwenta: { contract: KwentaToken, spender: KwentaStakingRewards },
            // vKwenta: { contract: vKwentaToken, spender: vKwentaRedeemer },
            // veKwenta: { contract: veKwentaToken, spender: veKwentaRedeemer },
            // kwentaStakingV2: { contract: KwentaToken, spender: KwentaStakingRewardsV2 },
        };
        const { contract, spender } = map[token];
        if (!contract || !spender) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(contract, 'approve', [
            ADDRESSES.KwentaStakingRewards[this.sdk.context.networkId],
            amount,
        ]);
    }
    approveToken(token, spender, amount = ethers.constants.MaxUint256) {
        const tokenContract = this.sdk.context.contracts[token];
        if (!tokenContract) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        let spenderAddress = this.sdk.context.walletAddress;
        if (spender) {
            const spenderContract = this.sdk.context.contracts[spender];
            if (spenderContract)
                spenderAddress = spenderContract.address;
        }
        return this.sdk.transactions.createContractTxn(tokenContract, 'approve', [
            spenderAddress,
            amount,
        ]);
    }
    redeemToken(token, options = { hasAddress: false }) {
        const tokenContract = this.sdk.context.contracts[token];
        if (!tokenContract) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(tokenContract, 'redeem', options.hasAddress ? [this.sdk.context.walletAddress] : []);
    }
    redeemVKwenta() {
        return this.redeemToken('vKwentaRedeemer');
    }
    redeemVeKwenta() {
        return this.redeemToken('veKwentaRedeemer', { hasAddress: true });
    }
    vestToken(ids) {
        const { RewardEscrow } = this.sdk.context.contracts;
        if (!RewardEscrow) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(RewardEscrow, 'vest', [ids]);
    }
    vestTokenV2(ids) {
        const { RewardEscrowV2 } = this.sdk.context.contracts;
        if (!RewardEscrowV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(RewardEscrowV2, 'vest', [ids]);
    }
    stakeKwenta(amount) {
        return this.performStakeAction('stake', amount);
    }
    unstakeKwenta(amount) {
        return this.performStakeAction('unstake', amount, { escrow: false, version: 1 });
    }
    stakeEscrowedKwenta(amount) {
        return this.performStakeAction('stake', amount, { escrow: true, version: 1 });
    }
    unstakeEscrowedKwenta(amount) {
        return this.performStakeAction('unstake', amount, { escrow: true, version: 1 });
    }
    stakeKwentaV2(amount) {
        return this.performStakeAction('stake', amount, { escrow: false, version: 2 });
    }
    unstakeKwentaV2(amount) {
        return this.performStakeAction('unstake', amount, { escrow: false, version: 2 });
    }
    stakeEscrowedKwentaV2(amount) {
        return this.performStakeAction('stake', amount, { escrow: true, version: 2 });
    }
    unstakeEscrowedKwentaV2(amount) {
        return this.performStakeAction('unstake', amount, { escrow: true, version: 2 });
    }
    getEstimatedRewards() {
        return __awaiter(this, void 0, void 0, function* () {
            const { networkId, walletAddress } = this.sdk.context;
            const fileNames = ['', '-op'].map((i) => `/${networkId === 420 ? 'goerli-' : ''}epoch-current${i}.json`);
            const responses = yield Promise.all(fileNames.map((fileName) => __awaiter(this, void 0, void 0, function* () {
                const response = yield awsClient.get(fileName);
                return Object.assign({}, response.data);
            })));
            const [estimatedKwentaRewards, estimatedOpRewards] = responses.map((d) => {
                const reward = d.claims[walletAddress];
                if (reward) {
                    return weiFromWei(reward.amount);
                }
                return ZERO_WEI;
            });
            return { estimatedKwentaRewards, estimatedOpRewards };
        });
    }
    getClaimableRewards(epochPeriod) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorPerpsV2 } = this.sdk.context.multicallContracts;
            const { walletAddress } = this.sdk.context;
            if (!MultipleMerkleDistributorPerpsV2) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
            const adjustedPeriods = periods.slice(TRADING_REWARDS_CUTOFF_EPOCH);
            const fileNames = adjustedPeriods.map((i) => `${this.sdk.context.networkId === 420 ? `goerli-` : ''}epoch-${i}.json`);
            const responses = yield Promise.all(fileNames.map((fileName, index) => __awaiter(this, void 0, void 0, function* () {
                const response = yield awsClient.get(fileName);
                const period = index + TRADING_REWARDS_CUTOFF_EPOCH;
                return Object.assign(Object.assign({}, response.data), { period });
            })));
            const rewards = responses
                .map((d) => {
                const reward = d.claims[walletAddress];
                if (reward) {
                    return [reward.index, walletAddress, reward.amount, reward.proof, d.period];
                }
                return null;
            })
                .filter((x) => !!x);
            const claimed = yield this.sdk.context.multicallProvider.all(rewards.map((reward) => MultipleMerkleDistributorPerpsV2.isClaimed(reward[0], reward[4])));
            const { totalRewards, claimableRewards } = rewards.reduce((acc, next, i) => {
                if (!claimed[i]) {
                    acc.claimableRewards.push(next);
                    acc.totalRewards = acc.totalRewards.add(weiFromWei(next[2]));
                }
                return acc;
            }, { claimableRewards: [], totalRewards: wei(0) });
            return { claimableRewards, totalRewards };
        });
    }
    getClaimableAllRewards(epochPeriod, isStakingV2 = false, isOp = false, isSnx = false, cutoffPeriod = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorPerpsV2, MultipleMerkleDistributorStakingV2, MultipleMerkleDistributorOp, MultipleMerkleDistributorSnxOp, } = this.sdk.context.multicallContracts;
            const { walletAddress } = this.sdk.context;
            if (!MultipleMerkleDistributorPerpsV2 ||
                !MultipleMerkleDistributorStakingV2 ||
                !MultipleMerkleDistributorOp ||
                !MultipleMerkleDistributorSnxOp) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
            const adjustedPeriods = isOp
                ? periods.slice(OP_REWARDS_CUTOFF_EPOCH)
                : isStakingV2
                    ? periods.slice(STAKING_V2_REWARDS_CUTOFF_EPOCH)
                    : periods.slice(TRADING_REWARDS_CUTOFF_EPOCH, STAKING_V2_REWARDS_CUTOFF_EPOCH);
            const fileNames = adjustedPeriods.map((i) => `${this.sdk.context.networkId === 420 ? `goerli-` : ''}epoch-${isSnx ? i - OP_REWARDS_CUTOFF_EPOCH : i}${isOp ? (isSnx ? '-snx-op' : '-op') : ''}.json`);
            const responses = yield Promise.all(fileNames.map((fileName, index) => __awaiter(this, void 0, void 0, function* () {
                try {
                    if (index < cutoffPeriod)
                        return null;
                    const response = yield awsClient.get(fileName);
                    const period = isOp
                        ? isSnx
                            ? index
                            : index + OP_REWARDS_CUTOFF_EPOCH
                        : isStakingV2
                            ? index + STAKING_V2_REWARDS_CUTOFF_EPOCH
                            : index + TRADING_REWARDS_CUTOFF_EPOCH;
                    return Object.assign(Object.assign({}, response.data), { period });
                }
                catch (err) {
                    this.sdk.context.logError(err);
                    return null;
                }
            })));
            const rewards = responses
                .filter(Boolean)
                .map((d) => {
                const reward = d.claims[walletAddress];
                if (reward) {
                    return [reward.index, walletAddress, reward.amount, reward.proof, d.period];
                }
                return null;
            })
                .filter((x) => !!x);
            const claimed = yield this.sdk.context.multicallProvider.all(rewards.map((reward) => isOp
                ? isSnx
                    ? MultipleMerkleDistributorSnxOp.isClaimed(reward[0], reward[4])
                    : MultipleMerkleDistributorOp.isClaimed(reward[0], reward[4])
                : isStakingV2
                    ? MultipleMerkleDistributorStakingV2.isClaimed(reward[0], reward[4])
                    : MultipleMerkleDistributorPerpsV2.isClaimed(reward[0], reward[4])));
            const { totalRewards, claimableRewards } = rewards.reduce((acc, next, i) => {
                if (!claimed[i]) {
                    acc.claimableRewards.push(next);
                    acc.totalRewards = acc.totalRewards.add(weiFromWei(next[2]));
                }
                return acc;
            }, { claimableRewards: [], totalRewards: wei(0) });
            return { claimableRewards, totalRewards };
        });
    }
    getKwentaRewardsByEpoch(epochPeriod) {
        return __awaiter(this, void 0, void 0, function* () {
            const { walletAddress } = this.sdk.context;
            const fileName = `${this.sdk.context.networkId === 420 ? `goerli-` : ''}epoch-${epochPeriod}.json`;
            try {
                const response = yield awsClient.get(fileName);
                const rewards = response.data.claims[walletAddress];
                return rewards ? weiFromWei(rewards.amount) : ZERO_WEI;
            }
            catch (err) {
                this.sdk.context.logError(err);
                return ZERO_WEI;
            }
        });
    }
    getKwentaRewardsByTraders(epochPeriod, traders) {
        return __awaiter(this, void 0, void 0, function* () {
            const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
            const adjustedPeriods = periods.slice(REFERRAL_PROGRAM_START_EPOCH);
            const fileNames = adjustedPeriods.map((i) => `${this.sdk.context.networkId === 420 ? `goerli-` : ''}epoch-${i}.json`);
            try {
                const responses = yield Promise.all(fileNames.map((fileName) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const response = yield awsClient.get(fileName);
                        return Object.assign({}, response.data);
                    }
                    catch (err) {
                        this.sdk.context.logError(err);
                        return null;
                    }
                })));
                const rewards = traders.map((walletAddress) => {
                    const lowerCaseWalletAddress = walletAddress.toLowerCase();
                    return responses
                        .filter(Boolean)
                        .map(({ claims }) => {
                        const lowerCaseClaims = Object.fromEntries(Object.entries(claims).map(([key, value]) => [key.toLowerCase(), value]));
                        const reward = lowerCaseClaims[lowerCaseWalletAddress];
                        return reward ? reward.amount : '0';
                    })
                        .reduce((acc, amount) => (amount ? acc.add(weiFromWei(amount)) : acc), ZERO_WEI);
                });
                return rewards
                    .flat()
                    .reduce((total, next) => (next ? total.add(weiFromWei(next)) : total), ZERO_WEI);
            }
            catch (err) {
                this.sdk.context.logError(err);
                return ZERO_WEI;
            }
        });
    }
    claimKwentaRewards(claimableRewards) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorPerpsV2 } = this.sdk.context.contracts;
            if (!MultipleMerkleDistributorPerpsV2) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return this.sdk.transactions.createContractTxn(MultipleMerkleDistributorPerpsV2, 'claimMultiple', [claimableRewards]);
        });
    }
    claimMultipleAllRewards(claimableRewards) {
        return __awaiter(this, void 0, void 0, function* () {
            const { BatchClaimer, MultipleMerkleDistributorPerpsV2, MultipleMerkleDistributorStakingV2, MultipleMerkleDistributorOp, MultipleMerkleDistributorSnxOp, } = this.sdk.context.contracts;
            if (!BatchClaimer ||
                !MultipleMerkleDistributorPerpsV2 ||
                !MultipleMerkleDistributorStakingV2 ||
                !MultipleMerkleDistributorOp ||
                !MultipleMerkleDistributorSnxOp) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return this.sdk.transactions.createContractTxn(BatchClaimer, 'claimMultiple', [
                [
                    MultipleMerkleDistributorPerpsV2.address,
                    MultipleMerkleDistributorStakingV2.address,
                    MultipleMerkleDistributorOp.address,
                    MultipleMerkleDistributorSnxOp.address,
                ],
                claimableRewards,
            ]);
        });
    }
    claimOpRewards(claimableRewards, isSnx = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const { MultipleMerkleDistributorOp, MultipleMerkleDistributorSnxOp } = this.sdk.context.contracts;
            if (!MultipleMerkleDistributorOp || !MultipleMerkleDistributorSnxOp) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            return this.sdk.transactions.createContractTxn(isSnx ? MultipleMerkleDistributorSnxOp : MultipleMerkleDistributorOp, 'claimMultiple', [claimableRewards]);
        });
    }
    getFuturesFee(start, end) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.isL2) {
                throw new Error(sdkErrors.REQUIRES_L2);
            }
            const response = yield getFuturesAggregateStats(this.sdk.futures.futuresGqlEndpoint, {
                first: DEFAULT_NUMBER_OF_FUTURES_FEE,
                where: {
                    asset: AGGREGATE_ASSET_KEY,
                    period: SECONDS_PER_DAY,
                    timestamp_gte: start,
                    timestamp_lt: end,
                },
                orderDirection: 'desc',
                orderBy: 'timestamp',
            }, { feesKwenta: true });
            return response ? calculateTotalFees(response) : wei(0);
        });
    }
    getFuturesFeeForAccount(account, start, end) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!account)
                return wei(0);
            const response = yield getFuturesTrades(this.sdk.futures.futuresGqlEndpoint, {
                first: DEFAULT_NUMBER_OF_FUTURES_FEE,
                where: {
                    account: account,
                    timestamp_gt: start,
                    timestamp_lt: end,
                    trackingCode: KWENTA_TRACKING_CODE,
                },
                orderDirection: 'desc',
                orderBy: 'timestamp',
            }, {
                feesPaid: true,
                keeperFeesPaid: true,
            });
            return response ? calculateFeesForAccount(response) : wei(0);
        });
    }
    performStakeAction(action, amount, options = { escrow: false, version: 1 }) {
        const { KwentaStakingRewards } = this.sdk.context.contracts;
        if (!KwentaStakingRewards) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const contract = KwentaStakingRewards;
        return this.sdk.transactions.createContractTxn(contract, `${action}`, [amount]);
    }
    approveOperator(delegatedAddress, isApproval) {
        const { KwentaStakingRewardsV2 } = this.sdk.context.contracts;
        if (!KwentaStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(KwentaStakingRewardsV2, 'approveOperator', [
            delegatedAddress,
            isApproval,
        ]);
    }
    getApprovedOperators() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.KwentaStakingRewardsV2) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            return queryOperatorsByOwner(this.sdk, walletAddress);
        });
    }
    transferFrom(from, to, id) {
        const { RewardEscrowV2 } = this.sdk.context.contracts;
        if (!RewardEscrowV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(RewardEscrowV2, 'transferFrom', [from, to, id]);
    }
    bulkTransferFrom(from, to, ids) {
        const { RewardEscrowV2 } = this.sdk.context.contracts;
        if (!RewardEscrowV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(RewardEscrowV2, 'bulkTransferFrom', [
            from,
            to,
            ids,
        ]);
    }
}
