var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { formatBytes32String } from '@ethersproject/strings';
import * as sdkErrors from '../common/errors';
import { getReferralStatisticsByAccount, getReferralStatisticsByAccountAndEpoch, getReferralStatisticsByAccountAndEpochTime, getReferralsGqlEndpoint, } from '../utils/referrals';
import { queryBoostNftTierByHolder, queryCodesByReferrer, queryReferrerByCode, } from '../queries/referrals';
export default class ReferralsService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    get referralsGqlEndpoint() {
        const { networkId } = this.sdk.context;
        return getReferralsGqlEndpoint(networkId);
    }
    /**
     * Mint a Boost NFT using the given code.
     * @param code - The referral code.
     * @returns ethers.js TransactionResponse object
     */
    mintBoostNft(code) {
        if (!this.sdk.context.contracts.BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(this.sdk.context.contracts.BoostNft, 'mint', [
            formatBytes32String(code),
        ]);
    }
    /**
     * Register a new referral code.
     * @param code - The referral code to register.
     * @returns ethers.js TransactionResponse object
     */
    registerReferralCode(code) {
        if (!this.sdk.context.contracts.BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.createContractTxn(this.sdk.context.contracts.BoostNft, 'registerCode', [formatBytes32String(code)]);
    }
    /**
     * Fetches the referral score by a given account.
     * @param account - The account of the referral code creator.
     * @returns The referral score of the account.
     */
    getReferralScoreByReferrer(account) {
        if (!this.sdk.context.contracts.BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.context.contracts.BoostNft.getReferralScore(account);
    }
    /**
     * Fetch the tier of a boost NFT determined by the issuer's referral score.
     * @param account - The account of the boost NFT issuer.
     * @returns The tier level of the boost NFT.
     */
    getReferralNftTierByReferrer(account) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.BoostNft) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            try {
                const score = yield this.getReferralScoreByReferrer(account);
                const tier = yield this.sdk.context.contracts.BoostNft.getTierFromReferralScore(score);
                return tier.toNumber();
            }
            catch (err) {
                this.sdk.context.logError(err);
                throw err;
            }
        });
    }
    /**
     * Retrieve the tier level associated with a given referral code.
     * @param code - The referral code.
     * @returns The tier level of the referral code, or -1 if the code is invalid.
     */
    getTierByReferralCode(code) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.BoostNft) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            try {
                const account = yield this.getReferrerByCode(code);
                if (!account) {
                    return -1;
                }
                return this.getReferralNftTierByReferrer(account);
            }
            catch (err) {
                this.sdk.context.logError(err);
                throw err;
            }
        });
    }
    /**
     * Check whether a Boost NFT has been minted by a given account.
     * @param account - The account to check.
     * @returns Boolean indicating whether the NFT has been minted.
     */
    checkNftMintedForAccount(account) {
        if (!this.sdk.context.contracts.BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.context.contracts.BoostNft.hasMinted(account);
    }
    /**
     * Retrieve the owner's address associated with a given referral code.
     * @param code - The referral code.
     * @returns The code owner's address, or null if the code is not found.
     */
    getReferrerByCode(code) {
        if (!this.sdk.context.contracts.BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return queryReferrerByCode(this.sdk, code);
    }
    /**
     * Fetch the tier of a Boost NFT by the owner's account.
     * @param account - The account of the Boost NFT owner.
     * @returns The tier level of a Boost NFT.
     */
    getBoostNftTierByHolder(account) {
        if (!this.sdk.context.contracts.BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return queryBoostNftTierByHolder(this.sdk, account);
    }
    /**
     * Retrieve all the referral codes created by a given referrer.
     * @param account - The account of the referrer.
     * @returns All the referral codes created by the referrer.
     */
    getCodesByReferrer(account) {
        if (!this.sdk.context.contracts.BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return queryCodesByReferrer(this.sdk, account);
    }
    /**
     * Retrieve the cumulative statistics for a given referrer.
     * @param account - The account of the referrer.
     * @returns Object containing total referrerd account and total referral volumes per code
     */
    getCumulativeStatsByReferrer(account) {
        if (!this.sdk.context.contracts.BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return getReferralStatisticsByAccount(this.sdk, account);
    }
    /**
     * Retrieve the cumulative statistics for a given referrer.
     * @param account - The account of the referrer.
     * @param period - The epoch period.
     * @returns Object containing total referrerd account and total referral volumes per code
     */
    getCumulativeStatsByReferrerAndEpoch(account, period) {
        if (!this.sdk.context.contracts.BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return getReferralStatisticsByAccountAndEpoch(this.sdk, account, period);
    }
    /**
     * Retrieve the cumulative statistics for a given referrer.
     * @param account - The account of the referrer.
     * @param start - The start epoch timestamp.
     * @param end - The end epoch timestamp.
     * @returns Object containing total referrerd account and total referral volumes per code
     */
    getCumulativeStatsByReferrerAndEpochTime(account, start, end) {
        if (!this.sdk.context.contracts.BoostNft) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return getReferralStatisticsByAccountAndEpochTime(this.sdk, account, start, end);
    }
}
