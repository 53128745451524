var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request, gql } from 'graphql-request';
import { formatBytes32String, parseBytes32String } from '@ethersproject/strings';
export const queryReferrerByCode = (sdk, code) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const response = yield request(sdk.referrals.referralsGqlEndpoint, gql `
			query boostReferrers($code: String!) {
				boostReferrers(where: { id: $code }) {
					account
				}
			}
		`, { code: formatBytes32String(code) });
    return ((_a = response === null || response === void 0 ? void 0 : response.boostReferrers[0]) === null || _a === void 0 ? void 0 : _a.account) || null;
});
export const queryBoostNftTierByHolder = (sdk, walletAddress) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    if (!walletAddress)
        return -1;
    const response = yield request(sdk.referrals.referralsGqlEndpoint, gql `
			query boostHolders($walletAddress: String!) {
				boostHolders(where: { id: $walletAddress }) {
					tier
				}
			}
		`, { walletAddress });
    return ((_b = response === null || response === void 0 ? void 0 : response.boostHolders[0]) === null || _b === void 0 ? void 0 : _b.tier) || -1;
});
export const queryCodesByReferrer = (sdk, walletAddress) => __awaiter(void 0, void 0, void 0, function* () {
    if (!walletAddress)
        return [];
    const response = yield request(sdk.referrals.referralsGqlEndpoint, gql `
			query boostReferrers($walletAddress: String!) {
				boostReferrers(where: { account: $walletAddress }) {
					id
				}
			}
		`, { walletAddress });
    return (response === null || response === void 0 ? void 0 : response.boostReferrers.map(({ id }) => parseBytes32String(id))) || [];
});
export const queryTradersByCode = (sdk, code, epochStart, epochEnd) => __awaiter(void 0, void 0, void 0, function* () {
    let queryResponseCount = 0;
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    const end = epochEnd ? Math.min(currentTimeInSeconds, epochEnd) : currentTimeInSeconds;
    const boostHolders = [];
    const minTimestamp = epochStart || 0;
    let maxTimestamp = end;
    do {
        const response = yield request(sdk.referrals.referralsGqlEndpoint, gql `
				query boostHolders($code: String!, $minTimestamp: BigInt!, $maxTimestamp: BigInt!) {
					boostHolders(
						where: { lastMintedAt_lte: $maxTimestamp, lastMintedAt_gt: $minTimestamp, code: $code }
						orderBy: lastMintedAt
						orderDirection: desc
						first: 1000
					) {
						id
						lastMintedAt
					}
				}
			`, { code: formatBytes32String(code), maxTimestamp, minTimestamp });
        queryResponseCount = response.boostHolders.length;
        if (queryResponseCount > 0) {
            maxTimestamp = Number(response.boostHolders[queryResponseCount - 1].lastMintedAt);
            boostHolders.push(...response.boostHolders);
        }
    } while (queryResponseCount === 1000);
    return boostHolders;
});
