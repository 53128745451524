/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from 'ethers';
const _abi = [
    {
        inputs: [
            {
                internalType: 'contract ENS',
                name: '_ens',
                type: 'address',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        inputs: [
            {
                internalType: 'address[]',
                name: 'addresses',
                type: 'address[]',
            },
        ],
        name: 'getNames',
        outputs: [
            {
                internalType: 'string[]',
                name: 'r',
                type: 'string[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];
export class ReverseRecords__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
ReverseRecords__factory.abi = _abi;
