// DEPLOYMENT: Change all addresses against 42161 chain id
export const ADDRESSES = {
    Exchanger: {
        1: '0xc0EaaC6be63FF28E7101337f7748b2fB3b63F16b',
        5: '0x889d8a97f43809Ef3FBb002B4b7a6A65319B61eD',
        10: '0xC37c47C55d894443493c1e2E615f4F9f4b8fDEa4',
        420: '0x601A1Cf1a34d9cF0020dCCD361c155Fe54CE24fB',
        42161: '0x28192CAeC673D14680F710a1CB1DA90c539Ce547',
    },
    UniswapFactoryV3: {
        1: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
        42161: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    },
    SystemStatus: {
        1: '0x696c905F8F8c006cA46e9808fE7e00049507798F',
        5: '0x31541f35F6Bd061f4A894fB7eEE565f81EE50df3',
        10: '0xE8c41bE1A167314ABAF2423b72Bf8da826943FFD',
        420: '0x9D89fF8C6f3CC22F4BbB859D0F85FB3a4e1FA916',
        42161: '0x4457F2a3f3F6EfdFB63152538e04aFF0482e2325',
    },
    ExchangeRates: {
        1: '0x462fe9131188fe6d86730321e8a15e29015d9fc9',
        5: '0xea765947303051507033202CAB7D3f5d4961CF5d',
        10: '0x0cA3985f973f044978d2381AFEd9c4D85a762d11',
        420: '0x061B75475035c20ef2e35E1002Beb90C3c1f24cC',
        42161: '0x1C24b67B19DdCA92E1816A3DdBFF1da277C9a1BD',
    },
    SynthUtil: {
        1: '0xb9faacb698fc940a9963cdbc0946abdf4a7714d4',
        5: '0x492395BA6866EF703DA49667fF92Cb8551e7a2D1',
        10: '0x87b1481c82913301Fc6c884Ac266a7c430F92cFA',
        420: '0xC647DecC9c4f9162dBF77E4367199F5ED0950355',
        42161: '0xFa908E683740c2a9Ae9B54BB95f00ed8c9546766',
    },
    SystemSettings: {
        1: '0x0c8324340dab66f0c2a4631bfb4755013d0e0ae0',
        5: '0xA1B0898C54124E06aEAa823dC46ad0C306Ca6CD5',
        10: '0x05E1b1Dff853B1D67828Aa5E8CB37cC25aA050eE',
        420: '0xD2cECA6DD62243aB2d342Eb04882c86a10b35274',
        42161: '0x07E963DD4A4b3C925Ef4dD4710673F2fe6c1F08A',
    },
    SynthRedeemer: {
        1: '0xe533139Af961c9747356D947838c98451015e234',
        5: '0x32A0BAA5Acec418a85Fd032f0292893B8E4f743B',
        10: '0xA997BD647AEe62Ef03b41e6fBFAdaB43d8E57535',
        420: '0x2A8338199D802620B4516a557195a498595d7Eb6',
        42161: '0xE24a638f280AFe68f2627fE241887D713bDD8929',
    },
    FuturesMarketData: {
        10: '0xC51aeDBEC3aCD26650a7E85B6909E8AEc4d0F19e',
        420: '0x3FAe35Cfea950Fada314589213BABC54A084d5Bf',
    },
    FuturesMarketSettings: {
        10: '0xaE55F163337A2A46733AA66dA9F35299f9A46e9e',
        420: '0x0dde87714C3bdACB93bB1d38605aFff209a85998',
    },
    PerpsV2MarketData: {
        10: '0x340B5d664834113735730Ad4aFb3760219Ad9112',
        420: '0xcE2dC389fc8Be231beECED1D900881e38596d7b2',
    },
    PerpsV2MarketSettings: {
        10: '0x649F44CAC3276557D03223Dbf6395Af65b11c11c',
        420: '0xedf10514EF611e3808622f24e236b83cB9E51dCe',
    },
    Pyth: {
        10: '0xff1a0f4744e8582DF1aE09D5611b887B6a12925C',
        420: '0xff1a0f4744e8582DF1aE09D5611b887B6a12925C',
    },
    cfUSD: {
        1: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
        10: '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
        420: '0xebaeaad9236615542844adc5c149f86c36ad1136',
        42161: '0x00BBf85b370dd635D049267721D64aE4449b90A9',
    },
    USDC: {
        1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        10: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
        42161: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    },
    USDT: {
        1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        10: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
        42161: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    },
    DAI: {
        1: '0x6b175474e89094c44da98b954eedeac495271d0f',
        10: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        42161: '0x6b175474e89094c44da98b954eedeac495271d0f',
    },
    LUSD: {
        10: '0xc40F949F8a4e094D1b49a23ea9241D289B7b2819',
    },
    SNXUSD: {
        420: '0xe487Ad4291019b33e2230F8E2FB1fb6490325260',
    },
    Synthetix: {
        1: '0x019aa3FFba2d48259dF68740fe76234e0B8d7FfB',
        5: '0x51f44ca59b867E005e48FA573Cb8df83FC7f7597',
        10: '0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4',
        420: '0x2E5ED97596a8368EB9E44B1f3F25B2E813845303',
        42161: '0x7CC06De0FbbA3235637aE5ae7EA09378B0554fbF',
    },
    SynthSwap: {
        1: '0x0a3faa36cB00a7BEc5DD9D745aF3786a99b26CCc',
        10: '0x6d6273f52b0C8eaB388141393c1e8cfDB3311De6',
        42161: '0xCA2aeE2232d89a3409291350E6E7D57c3eF6A5a6',
    },
    SmartMarginAccount: {
        10: '0x3BC8D34314E77c2E36948Fd8F4B353f1baDc3F6C',
        420: '0xf9CC1eD5EC9b8DFb9273fdDABD07C7bB37938285',
    },
    SmartMarginAccountFactory: {
        10: '0x8234F990b149Ae59416dc260305E565e5DAfEb54',
        420: '0x30582eeE34719fe22b1B6c3b607636A3ab94522E',
    },
    SmartMarginEvents: {
        10: '0x11193470df30B37Af9fc5Ec696c240D878bdfb42',
        420: '0xa7AE3969A128048290968b41865Eaa53B20FA69e',
    },
    KwentaArrakisVault: {
        10: '0x56dEa47c40877c2aaC2a689aC56aa56cAE4938d2',
    },
    StakingRewards: {
        1: '0x843a5cc3a686aafc3e5e65135fdc9ba0eb5f2054',
        10: '0x6077987e8e06c062094c33177Eb12c4A65f90B65',
        42161: '0xBa266f67C7812e53bD2F15A94672692464a7C8e4',
    },
    KwentaToken: {
        1: '0x463305d01d51246103ca8f73cd646100e7864a31',
        10: '0x920Cf626a271321C151D027030D5d08aF699456b',
        420: '0xDA0C33402Fc1e10d18c532F0Ed9c1A6c5C9e386C',
        42161: '0xF85E41e010C655edd13A32c3121cA5F29f223F26',
    },
    KwentaStakingRewards: {
        1: '0x843a5cc3a686aafc3e5e65135fdc9ba0eb5f2054',
        10: '0x6e56A5D49F775BA08041e28030bc7826b13489e0',
        420: '0x1653a3a3c4ccee0538685f1600a30df5e3ee830a',
        42161: '0xBa266f67C7812e53bD2F15A94672692464a7C8e4',
    },
    KwentaStakingRewardsV2: {
        1: '0x463305d01d51246103ca8f73cd646100e7864a31',
        10: '0x61294940CE7cD1BDA10e349adC5B538B722CeB88',
        420: '0xA2dF816B2C5D8d799069d6a8a9f8464D402b5D25',
        42161: '0xBa266f67C7812e53bD2F15A94672692464a7C8e4',
    },
    RewardEscrow: {
        10: '0x1066A8eB3d90Af0Ad3F89839b974658577e75BE2',
        420: '0xaFD87d1a62260bD5714C55a1BB4057bDc8dFA413',
    },
    RewardEscrowV2: {
        10: '0xb2a20fCdc506a685122847b21E34536359E94C56',
        420: '0xe331a7eeC851Ba702aA8BF43070a178451d6D28E',
    },
    SupplySchedule: {
        1: '0x5b262fa270da3fb27c89b475bcaf3927cfe39006',
        10: '0x3e8b82326Ff5f2f10da8CEa117bD44343ccb9c26',
        420: '0x671423b2e8a99882fd14bbd07e90ae8b64a0e63a',
        42161: '0xDB390c0fEcdfB269B8c235Ccec96d507AF0f3cf2',
    },
    vKwentaToken: {
        10: '0x6789D8a7a7871923Fc6430432A602879eCB6520a',
        420: '0xb897D76bC9F7efB66Fb94970371ef17998c296b6',
    },
    veKwentaToken: {
        10: '0x678d8f4ba8dfe6bad51796351824dcceceaeff2b',
        420: '0x3e52b5f840eafd79394c6359e93bf3ffdae89ee4',
    },
    vKwentaRedeemer: {
        10: '0x8132EE584bCD6f8Eb1bea141DB7a7AC1E72917b9',
        420: '0x03c3E61D624F279243e1c8b43eD0fCF6790D10E9',
    },
    veKwentaRedeemer: {
        10: '0xc7088AC8F287539567e458C7D08C2a1470Fd25B7',
        420: '0x86ca3CEbEA60101292EEFCd5802fD6e55D647c87',
    },
    TradingRewards: {
        1: '0x9ded11b18d4179cdc2db55f30cf9559fba0713e7',
        10: '0xf486A72E8c8143ACd9F65A104A16990fDb38be14',
        420: '0x74c0A3bD10634759DC8B4CA7078C8Bf85bFE1271',
        42161: '0x3E4e893eBF200e3921782Cc219BFef2e15a3e86A',
    },
    TradingRewardsPerpsV2: {
        10: '0x2787CC20e5ECb4BF1bfB79eAE284201027683179',
    },
    TradingRewardsStakingV2: {
        10: '0x195f6F7ca2268e1DEC03352786C350Eb61aBa307',
    },
    OpRewards: {
        10: '0x1964cF9d0c5c268DcF5a5d37F13600483237f6F5',
    },
    SnxOpRewards: {
        10: '0xD301BCC5D3229A0a6400F1030c382A5d503931E1',
    },
    BatchClaimer: {
        10: '0x6Fd879830D9b1EE5d4f9ef12f8D5deE916bebD0b',
    },
    DappMaintenance: {
        10: '0x54581A23F62D147AC76d454f0b3eF77F9D766058',
        420: '0x4259a2004A1E110A86564ff1441c37F1461F344F',
    },
    PerpsV3MarketProxy: {
        420: '0xf272382cB3BE898A8CdB1A23BE056fA2Fcf4513b',
    },
    PerpsV3AccountProxy: {
        420: '0x01C2f64ABd46AF20950736f3C3e1a9cfc5c36c82',
    },
    EscrowMigrator: {
        10: '0xC9aF789Ae606F69cF8Ed073A04eC92f2354b027d',
        420: '0x7dF905e163d54B4FE9a14aE01cF985BDc82c1A0F',
    },
    BoostNft: {
        10: '0xD3B8876073949D790AB718CAD21d9326a3adA60f',
    },
};
// {
//   "WETH": "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
//   "UniswapFactory": "0xf1D7CC64Fb4452F05c498126312eBE29f30Fbcf9",
//   "UniswapRouter": "0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24",
//   "UniswapFactoryV3": "0x1F98431c8aD98523631AE4a59f267346ea31F984",
//   "UniswapSwapRouter": "0xE592427A0AEce92De3Edee1F18E0157C05861564",
//   "NonfungiblePositionManager": "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
//   "SafeDecimalMath": "0xdE8d404156698e5cB81DC086Fcdda07C7319b424",
//   "SystemSettingsLib": "0x818B0c46deA4F947b875434EC7f9dFB9F24D6727",
//   "ExchangeSettlementLib": "0xF76Dd190A9933E6c8ae85E3F0533Cb1Ec519f443",
//   "AddressResolver": "0x0BfFdbDEaeC93b0C12C5DD9C19C41D2282d68b53",
//   "SynthUtil": "0xFa908E683740c2a9Ae9B54BB95f00ed8c9546766",
//   "CollateralUtil": "0x23b4B54e47Bf56e5280A9295c6670B7fE93Cf3A8",
//   "CollateralManagerState": "0xc813d60D021Ed62b0f6569840ce40FE29A1AeC6b",
//   "CollateralManager": "0x4dF81C662577Cd0Acd8Fdca59b3f8b29a3c3E2A8",
//   "CollateralEth": "0xF85E41e010C655edd13A32c3121cA5F29f223F26",
//   "ProxySFCX": "0x00BBf85b370dd635D049267721D64aE4449b90A9",
//   "ProxycfUSD": "0xE8b6A1892def5a4485F3AaDBf70656A6f007D935",
//   "ProxycfETH": "0x8a252C5398AAC5905b405FC1B25bf4628D2cCDB7",
//   "ProxyFeePool": "0x4457F2a3f3F6EfdFB63152538e04aFF0482e2325",
//   "SystemStatus": "0xEb89336a91Ef40256E8Cc22b3eeF43b4fa3E0df7",
//   "Issuer": "0xA570f0C7eb3fd7400E28c816f7F0238469953581",
//   "DebtCache": "0x28192CAeC673D14680F710a1CB1DA90c539Ce547",
//   "Exchanger": "0xBCAe360523D0B4A44c1a3B76373c40bc47ddebd3",
//   "ExchangeState": "0xE24a638f280AFe68f2627fE241887D713bDD8929",
//   "SynthRedeemer": "0x774EbEc18aCFA0Bf6beD1Be5491ac58746627401",
//   "Liquidator": "0xD8ddf94566A7019a0c2D42BFc76abbAcaCF87cEB",
//   "FlexibleStorage": "0x07E963DD4A4b3C925Ef4dD4710673F2fe6c1F08A",
//   "SystemSettings": "0xDA2A28c7f58f1905D9A5b01AbA479f5a6e8823E4",
//   "CircuitBreaker": "0x842C8D0D0189AE91306a7FA3876515CE8FB4354E",
//   "SynDexState": "0x934485e2D7C820208b4772f1B576e6FBC554c8ed",
//   "TokenStateSFCX": "0x02aDF987d2A755616b469608dC3DDA45D1224C06",
//   "TokenStatecfUSD": "0x40e408D6A81338633E306522398794dD36cAd503",
//   "TokenStatecfETH": "0x17D5B3894fEEeaA8dEdD3baC0d3454e79eCF9341",
//   "Depot": "0x43DAd97DDA05B75A7f4C7Df462c49b87cBEeD215",
//   "WrapperFactory": "0xa9D7b878C021055fD286fA708a5fce2FaCd92476",
//   "RewardEscrowV2": "0x3E4e893eBF200e3921782Cc219BFef2e15a3e86A",
//   "TradingRewards": "0x0a81d103E2d5f746D0cD135fBdFF3Ad434e236D1",
//   "DirectIntegrationManager": "0x7CC06De0FbbA3235637aE5ae7EA09378B0554fbF",
//   "SynDex": "0xDAED5bdAdF3fDd6b5AedCC28198aC9B0537422E5",
//   "FeePool": "0xE0222e79370B3deb4E3828a3D7B15cF3E9bfdde5",
//   "FeePoolEternalStorage": "0x629aACC7869145a7E5ae39D3df8566E82cA3D5C8",
//   "RewardEscrow": "0x8EE88EfA540ed8C29128E39B9cd009dB064170b3",
//   "EtherWrapper": "0xC44Ed7cEF96874A09c02F385226Fd31bddd670d4",
//   "FuturesMarketManager": "0x28c16C8A89db844afd54f417F599170563a98760",
//   "LiquidatorRewards": "0xE5aD863113b2a409E1b13ED77478f7AbA22Cc78a",
//   "SynDexDebtShare": "0xcF155157e98022785e3cA9337E0e9430C3055D89",
//   "SynthcfUSD": "0x680Bb36E6de7AE962F8a1f9C7947321B6F235906",
//   "SynthcfETH": "0xA3dd949c586265665b964e0a8A9f8B5Eb6335493",
//   "RewardEscrowV2Storage": "0x68438b19cD0E138fE5B90b1afE9c2808c16E88f3",
//   "RewardsDistribution": "0x2a49dCDfBF953AB44884CAC2eAEc0723fC3d2ba1",
//   "RewardEscrowV2Frozen": "0x1C24b67B19DdCA92E1816A3DdBFF1da277C9a1BD",
//   "EternalStorage": "0xCccF7a1248ff9A85041B86a4Ce5f7d3c77B5d5A8",
//   "DelegateApprovals": "0xAAb26EeCc4640d9aEC19c9adC34079FD8F542dFD",
//   "ExchangeRates": "0x044884B6CFa2897Ff411CF31FE40915503b78670",
//   "AggregatorETH": "0x06593e36a219415dc69a4C1CA99D3BDde98e3825",
//   "AggregatorCollateral": "0x505B954533236F6d9b1BD7fd166878d33aDdaf94",
//   "AggregatorIssuedSynths": "0x33BB0E90D053F771aD8DaEf18f48ED135737428D",
//   "AggregatorDebtRatio": "0x81E5e0f4188C9308E7737834359E7fe9D4f28b68",
//   "AggregatorcfGold": "0xa59890dc24669b5Cab3ed58b0E32ebd8098DB243",
//   "ProxycfGold": "0xF289b71e34D143cB03075D2b02D88316b5dE53D5",
//   "TokenStatecfGold": "0xFAf5EB83D0dc377aFb26357bB444BAFa16b84A52",
//   "SynthcfGold": "0x92feF53bea0a3389C61EbB53478F79031aC0d525",
//   "AggregatorcfWheat": "0x44d7289542e21cE44E279B6c8942f8d002d00AE4",
//   "ProxycfWheat": "0x2d7b8c543C77728776C036b713C24e627ecCF02F",
//   "TokenStatecfWheat": "0x5a191B9fAF8538F2fe1a0b18E9234721bD50b589",
//   "SynthcfWheat": "0x3D0186a82fd846a3b47E336d5781fDBbDC562d7d",
//   "AggregatorcfCrudeOil": "0xE3b18bC7399F3d6AE044656245d102CB08a5949A",
//   "ProxycfCrudeOil": "0x089BA11c35577615b3b7Cf5f7A6349ac35e5651F",
//   "TokenStatecfCrudeOil": "0xfE0703D99328c749D22ab370a0462e63BE27082A",
//   "SynthcfCrudeOil": "0x8E77BCe8EC7Bb7fbB4DF37FAa85A9dc7C49F2620",
//   "AggregatorcfOrangeJuice": "0xa4ca5b306df42205FEf3f9Fef0B7547b2Ee157Ad",
//   "ProxycfOrangeJuice": "0x26e67b92B704055A8399Bb13d5A836e9a0901785",
//   "TokenStatecfOrangeJuice": "0x6a9307267Ee4dD12159c2F54f741EE54d819B8Ea",
//   "SynthcfOrangeJuice": "0x8c29F8A52921C201172373943f08A42242ec747c",
//   "AggregatorcfSilver": "0xDF21c649BFe70A768f84437933359A0E3b732444",
//   "ProxycfSilver": "0xccB2eB849126D83252fAe4F7Fa52B69C1BfA94c8",
//   "TokenStatecfSilver": "0x4863827993d35F339D1161330204Eb09009D6B57",
//   "SynthcfSilver": "0x2C5f05f408372FfFa57B9153229CDc996437962B",
//   "AggregatorcfPlatinum": "0xB7F5E8280EbD45961ea44994A947A29141428Ae0",
//   "ProxycfPlatinum": "0x30a463c5713c9691c0893d05b27cd5F3F47D26C7",
//   "TokenStatecfPlatinum": "0x4188E95Ba35D1bfb124bc915fC95F4CA59639901",
//   "SynthcfPlatinum": "0xaf7477C3457F14220849c92E8d52607eae2c7feE",
//   "AggregatorcfPalladium": "0x9092b4Cc7E2159925F17cA1861Ce44e6C9fB6822",
//   "ProxycfPalladium": "0x1b227D47cA42C984C52EB875E5B7F4BF2e645b2d",
//   "TokenStatecfPalladium": "0x35c1F69De594690Dab24495C6915e76c8C052f27",
//   "SynthcfPalladium": "0x9c4aCA34A1Bbd11a3eCEdd49B76d8ba4DF2a48fE",
//   "AggregatorcfLivestock": "0x3cE6F016Fb577a72C080981bB84F02e9fe362b41",
//   "ProxycfLivestock": "0x2fF32929712B86539974CA2fa18B1c2F81B65e0f",
//   "TokenStatecfLivestock": "0xEb59d0Bf35639a31dEd070222BD4CdE7ab8C0656",
//   "SynthcfLivestock": "0xA35aE6409b2418f011c9D11551402Ce62Fd6337c",
//   "AggregatorcfCoffee": "0xD0e018033E657696E60562223fA54a1410f590cB",
//   "ProxycfCoffee": "0xc3BfA06022f3D30B9D1309aE96b81525F62F0fAA",
//   "TokenStatecfCoffee": "0x2e2466f8493f7e2b82FAE9821BD5D33f441D2CF0",
//   "SynthcfCoffee": "0x76f5dEd8F7a6a0305A26cE6684cd7752DfCCAA4c",
//   "AggregatorcfSugar": "0xe9685E0f1F6F4e24c076dE59a07f2Ce96D75BBa4",
//   "ProxycfSugar": "0x022882fF5629DBaDd87f11a9393b76da35283F17",
//   "TokenStatecfSugar": "0xd018b77f6a24A6E9d9fcf8ba17464da883a6773C",
//   "SynthcfSugar": "0xa625310AB85769B045c5fA26122fBe9Baa0D773a",
//   "AggregatorcfCotton": "0xB8E51360594DB25CB41a1834e5B1ee3F7efaCF8B",
//   "ProxycfCotton": "0x75ed61A1C28A12d1329cF86D017cd2C35e8A1C04",
//   "TokenStatecfCotton": "0x6E0af50a35b732Ed1814f8A3De44Fbb1597eb8cb",
//   "SynthcfCotton": "0x1161bBac4139A18610274D902BA74BF039E187ac",
//   "AggregatorcfSoybeans": "0x7cb204Adcb2D54593A56708d95e0938ff30C1A24",
//   "ProxycfSoybeans": "0x48859e42BCe1408E0f048DB488feA6dc6004dEaC",
//   "TokenStatecfSoybeans": "0xD99DAdBE2D8bE3366f9c262258aB169E19D1BF88",
//   "SynthcfSoybeans": "0x1c87464b20A19a582B1D6c02a9a2839bF63934D3",
//   "AggregatorcfNaturalGas": "0x43E914B5f12ACa150d94A249fB42DDb5195717e9",
//   "ProxycfNaturalGas": "0xd3316bf88576C085e5C818d61a00D81fE79A0C17",
//   "TokenStatecfNaturalGas": "0x84d1A29a41d420859Ad16bB0219E40e7bBcd7C77",
//   "SynthcfNaturalGas": "0x9B8204fC0499Cfe7a4C3403b5595608e2768B559",
//   "AggregatorcfIron": "0x4320b6b67cF6b4894c509707EafF53c4aB3d5B11",
//   "ProxycfIron": "0x1f94936896F1BB1bf48CA0f7a1e1823B5424D843",
//   "TokenStatecfIron": "0xbD19F74D9D0317692b60264F5a9996E8F95c40cF",
//   "SynthcfIron": "0x7D03C0da498E3B97136d0Aa01446977a9fc385E1",
//   "AggregatorcfCocoa": "0x35E8FCAab86782fc3E4E80C0cb27F60F85b2cb61",
//   "ProxycfCocoa": "0x0af89846cD636dADa9ADfe95FAc424F36Fbdf946",
//   "TokenStatecfCocoa": "0x9466a7C688Ed38003b6E9452Bf16AedC79EF6fed",
//   "SynthcfCocoa": "0xf95C29104aFd7179a5fd23D6b2327052D6A65e76",
//   "AggregatorcfSteel": "0x73C0AD9125205D541691bc070EB2fBFfc182e9DF",
//   "ProxycfSteel": "0x3ae4E806291C0031FddE6Ce3601A9112A48Ac5D0",
//   "TokenStatecfSteel": "0x9359FAc281C6F2812601185eC7e04bf72cA867a6",
//   "SynthcfSteel": "0x100719678E9d86c605dEf17488538DD7F93C1C78",
//   "AggregatorcfCopper": "0x4002d017E77357F8f294addBBC22404A6b76Ea0F",
//   "ProxycfCopper": "0x2Ca6a983e904a5553B4ae837c47f5cd4bcA32aBF",
//   "TokenStatecfCopper": "0x94235441147E5b5fE9720178433BDF37566636b0",
//   "SynthcfCopper": "0x92C7EC01CC2e02B5d4f74F870Fca1e70D3edb0cc",
//   "SynthSwap": "0xCA2aeE2232d89a3409291350E6E7D57c3eF6A5a6",
//   "Staking": "0xBa266f67C7812e53bD2F15A94672692464a7C8e4",
//   "TestToken": "0x239D62d0C61e93215C5b3ec5E203072c669691B8",
//   "SFCXWETH": "0x8aE0a2cbAFAB17E440fd069955B829cb2566f89f",
//   "TKNUSD": "0xd14f6aa3eA20de933404e32B2f8215dF1B75C9B0",
//   "AggregatorSynth": "0xd211a1D78A369dBF28fC4a8FD6c0Ef9058856D07"
// }
