var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseBytes32String } from '@ethersproject/strings';
import { EvmPriceServiceConnection } from '@pythnetwork/pyth-evm-js';
import { wei } from '@synthetixio/wei';
import { formatEther } from 'ethers/lib/utils.js';
import request, { gql } from 'graphql-request';
import { throttle } from 'lodash';
import * as sdkErrors from '../common/errors';
import { MARKETS, MARKET_ASSETS_BY_PYTH_ID } from '../constants/futures';
import { PERIOD_IN_SECONDS } from '../constants/period';
import { PRICE_UPDATE_THROTTLE, PYTH_IDS } from '../constants/prices';
import { MarketAssetByKey, getDisplayAsset, getPythNetworkUrl, normalizePythId, } from '../utils/futures';
import { startInterval } from '../utils/interval';
import { scale } from '../utils/number';
import { getRatesEndpoint } from '../utils/prices';
const DEBUG_WS = false;
const LOG_WS = process.env.NODE_ENV !== 'production' && DEBUG_WS;
const DEFAULT_PRICE_SERVER = 'KWENTA';
//process.env.NEXT_PUBLIC_DEFAULT_PRICE_SERVICE === 'KWENTA' ? 'KWENTA' : 'PYTH'
export default class PricesService {
    constructor(sdk) {
        this.offChainPrices = {};
        this.onChainPrices = {};
        this.lastConnectionTime = Date.now();
        this.wsConnected = false;
        this.server = DEFAULT_PRICE_SERVER;
        this.throttleOffChainPricesUpdate = throttle((offChainPrices) => {
            this.sdk.context.events.emit('prices_updated', {
                prices: offChainPrices,
                type: 'off_chain',
                source: 'stream',
            });
        }, PRICE_UPDATE_THROTTLE);
        this.sdk = sdk;
        this.setEventListeners();
        // this.connectToPyth(sdk.context.networkId, this.server)
    }
    get currentPrices() {
        return {
            onChain: this.onChainPrices,
            offChain: this.offChainPrices,
        };
    }
    get pythIds() {
        return this.sdk.context.isMainnet ? PYTH_IDS.mainnet : PYTH_IDS.testnet;
    }
    /**
     * @desc Get offchain price for a given market
     * @param marketKey - Futures market key
     * @returns Offchain price for specified market
     * @example
     * ```ts
     * const sdk = new KwentaSDK();
     * const price = sdk.prices.getOffchainPrice(FuturesMarketKey.sBTCPERP);
     * console.log(price);
     * ```
     */
    getOffchainPrice(marketKey) {
        const price = this.offChainPrices[MarketAssetByKey[marketKey]];
        if (!price)
            throw new Error(`No price data for ${marketKey}`);
        return price;
    }
    /**
     * @desc Start polling pyth price updates
     * @param intervalTime - Polling interval in milliseconds
     * @example
     * ```ts
     * const sdk = new KwentaSDK();
     * await sdk.prices.startPriceUpdates(10000);
     * ```
     */
    startPriceUpdates(intervalTime) {
        return __awaiter(this, void 0, void 0, function* () {
            // Poll the onchain prices
            if (!this.ratesInterval) {
                this.ratesInterval = startInterval(() => __awaiter(this, void 0, void 0, function* () {
                    try {
                        this.onChainPrices = yield this.getOnChainPrices();
                        this.sdk.context.events.emit('prices_updated', {
                            prices: this.onChainPrices,
                            type: 'on_chain',
                        });
                    }
                    catch (err) {
                        this.sdk.context.logError(err);
                    }
                }), intervalTime);
            }
        });
    }
    onPricesUpdated(listener) {
        return this.sdk.context.events.on('prices_updated', listener);
    }
    removePricesListener(listener) {
        return this.sdk.context.events.off('prices_updated', listener);
    }
    removePricesListeners() {
        this.sdk.context.events.removeAllListeners('prices_updated');
    }
    onPricesConnectionUpdated(listener) {
        return this.sdk.context.events.on('prices_connection_update', listener);
    }
    removeConnectionListeners() {
        this.sdk.context.events.removeAllListeners('prices_connection_update');
    }
    getOnChainPrices() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.multicallContracts.SynthUtil) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const synthPrices = {};
            const [synthsRates] = (yield this.sdk.context.multicallProvider.all([
                this.sdk.context.multicallContracts.SynthUtil.synthsRates(),
            ]));
            const synths = synthsRates[0];
            const synthRates = synthsRates[1];
            synths.forEach((currencyKeyBytes32, i) => {
                const currencyKey = parseBytes32String(currencyKeyBytes32);
                const marketAsset = MarketAssetByKey[currencyKey];
                const rate = Number(formatEther(synthRates[i]));
                const price = wei(rate);
                synthPrices[currencyKey] = price;
                if (marketAsset)
                    synthPrices[marketAsset] = price;
            });
            return synthPrices;
        });
    }
    getOffChainPrices() {
        return __awaiter(this, void 0, void 0, function* () {
            const pythPrices = yield this.pyth.getLatestPriceFeeds(this.pythIds);
            return this.formatOffChainPrices(pythPrices !== null && pythPrices !== void 0 ? pythPrices : []);
        });
    }
    getPreviousDayPrices(marketAssets, networkId) {
        return __awaiter(this, void 0, void 0, function* () {
            const ratesEndpoint = getRatesEndpoint(networkId || this.sdk.context.networkId);
            const minTimestamp = Math.floor((Date.now() - PERIOD_IN_SECONDS.ONE_DAY * 1000) / 1000);
            const rateUpdateQueries = marketAssets.map((asset) => {
                var _a, _b;
                const graphqlSafeAssetName = /^\d/.test(asset) ? `_${asset}` : asset;
                return gql `
			# last before timestamp
			${graphqlSafeAssetName}: rateUpdates(
				first: 1
				where: { synth: "${(_b = (_a = getDisplayAsset(asset)) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== null && _b !== void 0 ? _b : asset}", timestamp_gte: $minTimestamp }
				orderBy: timestamp
				orderDirection: asc
			) {
				synth
				rate
			}
		`;
            });
            const response = yield request(ratesEndpoint, gql `
				query rateUpdates($minTimestamp: BigInt!) {
					${rateUpdateQueries.reduce((acc, curr) => {
                return acc + curr;
            })}
			}`, {
                minTimestamp: minTimestamp,
            });
            return (response ? Object.values(response).flat() : []);
        });
    }
    /**
     * @desc Get pyth price update data for a given market
     * @param marketKey Futures market key
     * @returns Pyth price update data
     */
    getPythPriceUpdateData(marketKey) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const pythIds = (_a = MARKETS[marketKey]) === null || _a === void 0 ? void 0 : _a.pythIds;
            const pythId = pythIds ? pythIds[this.sdk.context.isMainnet ? 'mainnet' : 'testnet'] : null;
            if (!pythId)
                throw new Error(sdkErrors.NO_PYTH_ID);
            const updateData = yield this.pyth.getPriceFeedsUpdateData([pythId]);
            return updateData;
        });
    }
    formatOffChainPrices(pythPrices) {
        var _a;
        const offChainPrices = (_a = pythPrices === null || pythPrices === void 0 ? void 0 : pythPrices.reduce((acc, p) => {
            const price = this.formatPythPrice(p);
            // Have to handle inconsistent id formatting between ws and http
            const id = normalizePythId(p.id);
            const marketKey = MARKET_ASSETS_BY_PYTH_ID[id];
            if (marketKey) {
                acc[marketKey] = price;
            }
            return acc;
        }, {})) !== null && _a !== void 0 ? _a : {};
        return offChainPrices;
    }
    connectToPyth(networkId, server) {
        if (this.pyth) {
            this.pyth.closeWebSocket();
        }
        this.pyth = new EvmPriceServiceConnection(getPythNetworkUrl(networkId, server), {
            logger: LOG_WS ? console : undefined,
        });
        this.lastConnectionTime = Date.now();
        this.monitorConnection();
        this.pyth.onWsError = (error) => {
            if (error === null || error === void 0 ? void 0 : error.message) {
                this.sdk.context.logError(error);
            }
            this.setWsConnected(false);
            this.sdk.context.events.emit('prices_connection_update', {
                connected: false,
                error: error || new Error('pyth prices ws connection failed'),
            });
        };
        this.subscribeToPythPriceUpdates();
    }
    setWsConnected(connected) {
        if (connected !== this.wsConnected) {
            this.wsConnected = connected;
            this.sdk.context.events.emit('prices_connection_update', {
                connected: connected,
            });
        }
    }
    setEventListeners() {
        this.sdk.context.events.on('network_changed', (params) => {
            this.connectToPyth(params.networkId, this.server);
        });
    }
    monitorConnection() {
        // Should get a constant stream of messages so when we don't
        // receive any for a 10 second period we switch servers
        if (this.connectionMonitorId)
            clearTimeout(this.connectionMonitorId);
        this.connectionMonitorId = setTimeout(() => {
            if (Date.now() - this.lastConnectionTime > 10000) {
                this.switchConnection();
            }
            this.monitorConnection();
        }, 1000);
    }
    switchConnection() {
        this.server = this.server === 'KWENTA' ? 'PYTH' : 'KWENTA';
        this.connectToPyth(this.sdk.context.networkId, this.server);
    }
    formatPythPrice(priceFeed) {
        const price = priceFeed.getPriceUnchecked();
        return scale(wei(price.price), price.expo);
    }
    subscribeToPythPriceUpdates() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.offChainPrices = yield this.getOffChainPrices();
                this.sdk.context.events.emit('prices_updated', {
                    prices: this.offChainPrices,
                    type: 'off_chain',
                    source: 'fetch',
                });
            }
            catch (err) {
                this.sdk.context.logError(err);
            }
            this.pyth.subscribePriceFeedUpdates(this.pythIds, (priceFeed) => {
                const id = normalizePythId(priceFeed.id);
                const assetKey = MARKET_ASSETS_BY_PYTH_ID[id];
                if (assetKey) {
                    const price = this.formatPythPrice(priceFeed);
                    this.offChainPrices[assetKey] = price;
                }
                this.setWsConnected(true);
                this.lastConnectionTime = Date.now();
                this.throttleOffChainPricesUpdate(this.offChainPrices);
            });
        });
    }
}
