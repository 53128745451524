var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import request, { gql } from 'graphql-request';
export const queryPerpsV3Markets = (sdk) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request(sdk.perpsV3.subgraphUrl, gql `
			query {
				markets {
					id
					perpsMarketId
					marketOwner
					marketName
					marketSymbol
					feedId
					owner
					maxFundingVelocity
					skewScale
					liquidationRewardRatioD18
					maxLiquidationLimitAccumulationMultiplier
					lockedOiPercent
					makerFee
					takerFee
				}
			}
		`);
    return response.markets;
});
export const queryPerpsV3Accounts = (sdk, walletAddress) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request(sdk.perpsV3.subgraphUrl, gql `
			query GetAccounts($owner: String) {
				accounts(where: { owner_contains: $owner }) {
					id
					accountId
					owner
				}
			}
		`, {
        owner: walletAddress,
    });
    return response.accounts;
});
export const querySettlementStrategies = (sdk) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request(sdk.perpsV3.subgraphUrl, gql `
			query GetSettlementStrategies {
				settlementStrategies(orderBy: "marketId", orderDirection: "desc") {
					id
					marketId
					strategyId
					strategyType
					settlementDelay
					settlementWindowDuration
					url
					settlementReward
					priceDeviationTolerance
				}
			}
		`);
    return response.settlementStrategies;
});
