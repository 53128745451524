/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from 'ethers';
const _abi = [
    {
        inputs: [{ internalType: 'address', name: 'resolver', type: 'address' }],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        inputs: [],
        name: 'addressResolver',
        outputs: [{ internalType: 'contract IAddressResolver', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'synthsBalances',
        outputs: [
            { internalType: 'bytes32[]', name: '', type: 'bytes32[]' },
            { internalType: 'uint256[]', name: '', type: 'uint256[]' },
            { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'synthsRates',
        outputs: [
            { internalType: 'bytes32[]', name: '', type: 'bytes32[]' },
            { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'synthsTotalSupplies',
        outputs: [
            { internalType: 'bytes32[]', name: '', type: 'bytes32[]' },
            { internalType: 'uint256[]', name: '', type: 'uint256[]' },
            { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'bytes32', name: 'currencyKey', type: 'bytes32' },
        ],
        name: 'totalSynthsInKey',
        outputs: [{ internalType: 'uint256', name: 'total', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
];
export class SynthUtil__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
SynthUtil__factory.abi = _abi;
