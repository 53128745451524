export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["AwaitingExecution"] = "AwaitingExecution";
    TransactionStatus["Executed"] = "Executed";
    TransactionStatus["Confirmed"] = "Confirmed";
    TransactionStatus["Failed"] = "Failed";
})(TransactionStatus || (TransactionStatus = {}));
// DEPLOYMENT: Change/Add network ID
export const NetworkIdByName = {
    mainnet: 1,
    goerli: 5,
    'goerli-ovm': 420,
    'mainnet-ovm': 10,
    kovan: 42,
    'kovan-ovm': 69,
    'mainnet-fork': 31337,
    arbitrum: 42161,
};
// DEPLOYMENT: Change/Add network ID
export const NetworkNameById = {
    1: 'mainnet',
    5: 'goerli',
    42: 'kovan',
    10: 'mainnet-ovm',
    69: 'kovan-ovm',
    420: 'goerli-ovm',
    42161: 'arbitrum',
    31337: 'mainnet-fork',
};
