var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getContractFactory, predeploys } from '@eth-optimism/contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { wei } from '@synthetixio/wei';
import { ethers } from 'ethers';
import { omit, clone } from 'lodash';
import * as sdkErrors from '../common/errors';
import { getEthGasPrice } from '../common/gas';
import { TRANSACTION_EVENTS_MAP } from '../constants/transactions';
import { NetworkIdByName } from '../types/common';
import { createEmitter, getRevertReason } from '../utils/transactions';
const OVMGasPriceOracle = getContractFactory('OVM_GasPriceOracle').attach(predeploys.OVM_GasPriceOracle);
const contractAbi = JSON.parse(OVMGasPriceOracle.interface.format(ethers.utils.FormatTypes.json));
const DEFAULT_GAS_BUFFER = 0.2;
export default class TransactionsService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    // Copied over from: https://github.com/Synthetixio/js-monorepo
    hash(transactionHash) {
        const emitter = createEmitter();
        setTimeout(() => this.watchTransaction(transactionHash, emitter), 5);
        return emitter;
    }
    watchTransaction(transactionHash, emitter) {
        return __awaiter(this, void 0, void 0, function* () {
            emitter.emit(TRANSACTION_EVENTS_MAP.txSent, { transactionHash });
            const { status, blockNumber, transactionHash: hash, } = yield this.sdk.context.provider.waitForTransaction(transactionHash);
            if (status === 1) {
                emitter.emit(TRANSACTION_EVENTS_MAP.txConfirmed, {
                    status,
                    blockNumber,
                    transactionHash: hash,
                });
            }
            else {
                setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                    const { chainId } = yield this.sdk.context.provider.getNetwork();
                    try {
                        const revertReason = yield getRevertReason({
                            txHash: transactionHash,
                            networkId: chainId,
                            blockNumber,
                            provider: this.sdk.context.provider,
                        });
                        emitter.emit(TRANSACTION_EVENTS_MAP.txFailed, {
                            transactionHash,
                            failureReason: revertReason,
                        });
                    }
                    catch (e) {
                        emitter.emit(TRANSACTION_EVENTS_MAP.txFailed, {
                            transactionHash,
                            failureReason: 'Transaction reverted for an unknown reason',
                        });
                    }
                }), 5000);
            }
        });
    }
    createContractTxn(contract, method, args, txnOptions = {}, options) {
        const txn = Object.assign({ to: contract.address, data: contract.interface.encodeFunctionData(method, args), value: BigNumber.from(0) }, txnOptions);
        return this.createEVMTxn(txn, options);
    }
    prepareContractTxn(contract, method, args, txnOptions = {}) {
        return Object.assign({ to: contract.address, data: contract.interface.encodeFunctionData(method, args), value: BigNumber.from(0) }, txnOptions);
    }
    createEVMTxn(txn, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const execTxn = clone(txn);
            if (!execTxn.gasLimit) {
                const newGasLimit = yield this.estimateGas(execTxn);
                execTxn.gasLimit = wei(newGasLimit !== null && newGasLimit !== void 0 ? newGasLimit : 0, 9)
                    .mul(1 + ((options === null || options === void 0 ? void 0 : options.gasLimitBuffer) || DEFAULT_GAS_BUFFER))
                    .toBN();
            }
            const txnData = yield this.sdk.context.signer.sendTransaction(execTxn);
            return txnData;
        });
    }
    createSynthetixTxn(contractName, method, args, txnOptions = {}, options) {
        const contract = this.sdk.context.contracts[contractName];
        if (!contract) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.createContractTxn(contract, method, args, txnOptions, options);
    }
    estimateGas(txn) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.sdk.context.signer.estimateGas(omit(txn, ['gasPrice', 'maxPriorityFeePerGas', 'maxFeePerGas']));
        });
    }
    getOptimismLayerOneFees(txn) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!txn || !this.sdk.context.signer)
                return null;
            const isNotOvm = this.sdk.context.networkId !== NetworkIdByName['mainnet-ovm'] &&
                this.sdk.context.networkId !== NetworkIdByName['kovan-ovm'] &&
                this.sdk.context.networkId !== NetworkIdByName['goerli-ovm'];
            if (isNotOvm) {
                return null;
            }
            const OptimismGasPriceOracleContract = new ethers.Contract(OVMGasPriceOracle.address, contractAbi, this.sdk.context.signer);
            const cleanedTxn = omit(txn, ['from', 'maxPriorityFeePerGas', 'maxFeePerGas']);
            const serializedTxn = ethers.utils.serializeTransaction(cleanedTxn);
            return wei(yield OptimismGasPriceOracleContract.getL1Fee(serializedTxn));
        });
    }
    getGasPrice() {
        return getEthGasPrice(this.sdk.context.networkId, this.sdk.context.provider);
    }
    signTypedData({ domain, types, values, }) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.sdk.context.signer._signTypedData(domain, types, values);
        });
    }
}
